@import '../../../../styles/index.scss';

.table_card {
  background: $white;
  border-radius: 10px;
}

.table_name {
  padding: 27px 60px;
  font-style: normal;
  font-weight: $font-weight-bolder;
  font-size: 14;
  color: $black;
}

.client_actions {
  display: flex;
  flex-wrap: nowrap;

  svg {
    width: 25px;
    height: 100%;
    margin-left: 5px;
    cursor: pointer;
  }
}

.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  margin: auto 0;
}

.name_cell_container {
  display: flex;
  align-items: center;

  .client_info {
    margin-left: 20px;

    .name {
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      color: $middleGray;
    }
  }
}

.mobile_card_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 20px;

  .client_info_and_message_container {
    display: flex;
    flex-direction: column;
    width: 80%;

    .client_info_container {
      display: flex;
      align-items: center;

      .client_info {
        margin-left: 20px;

        .name {
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          margin-bottom: 5px;
        }

        .description {
          font-weight: 400;
          font-size: 14px;
          color: $middleGray;
        }
      }
    }

    .notification_message {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      padding-top: 11px;
    }
  }
}

.header_container {
  padding: 40px 0 0 63px;
  display: flex;

  .header_cell {
    display: flex;
    margin-right: 50px;

    .name {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      margin-right: 20px;
    }

    .value {
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
    }
  }
}