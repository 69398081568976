@import '../../../../../../../styles/index';

.salon_picker {
    padding: 40px 0;
}

.salon_start_time_step_first_preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    font-weight: 700;
    line-height: 46px;
    font-size: 4vw;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: -0.4vw;
    color: $lightYellow;

    & > span {
      color: $gradientBlue;
      font-size: 4vw;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: -0.4vw;
    }
  }
}

.salon_start_time_step_second_preview {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    font-weight: 700;
    line-height: 46px;
    font-size: 3vw;
    font-style: normal;
    text-transform: uppercase;
    color: $lightYellow;

    .blue_inject {
      color: $gradientBlue;
      font-size: 3vw;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
    }

    .gold_inject {
      color: $gold;
      font-size: 3vw;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
    }
  }
}