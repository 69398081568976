@import '../../../../styles/index';

.header_container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 90%;
  margin-left: 70px;

  &__search {
    width: 319px;
  }

  &__item {
    display: flex;
    align-items: center;
    height: 100%;

    &:first-child {
      margin-right: auto;
    }

    &:last-child {
      margin-left: auto;
    }

    .extra_time_selector_container {
      margin-left: 20px;
    }
  }

  .calendar_today_button {
    background: $black;
    border-radius: 10px;
    text-transform: none;
    font-weight: 700;
    font-size: 14px;
    margin-right: 15px;
    width: 59px;
    height: 44px;
  }
}

.header_container_mobile {
  display: flex;
  flex-direction: column;
  width: 100%;

  .filter_and_search_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 65px;
    align-items: center;

    .filter_icons_container {
      margin-right: 20px;
      display: flex;
      align-items: center;

      svg {
        cursor: pointer;
        width: 28px;
        height: 28px;
        margin-left: 15px;
      }
    }

    .header_container__search {
      width: fit-content;
      margin-left: 70px;
    }
  }

  .date_container {
    display: flex;
    justify-content: space-between;
    padding-left: 13px;
    padding-top: 10px;

    .calendar_today_button {
      background: $black;
      border-radius: 10px;
      text-transform: none;
      font-weight: 700;
      font-size: 14px;
      margin-right: 15px;
    }
  }
}