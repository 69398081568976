@import '../../../../styles/index.scss';

.toast_success {
  background: $bluesky !important;
  color: $textGray !important;
  border-radius: 12px !important;
}

.toast_success_progress {
  background: $blueskyligth !important;
  border-radius: 12px !important;
}

.toast_warning {
  background: $redligth !important;
  color: $textGray !important;
  border-radius: 12px !important;
}

.toast_warning_progress {
  background: $red !important;
  border-radius: 12px !important;
}