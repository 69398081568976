@import '../.././../../../styles/index.scss';

.container {
    display: flex;
    justify-content: space-between;
    height: fit-content;
    gap: 15px;
    flex-wrap: nowrap;
    margin-bottom: 30px;

    .settings {
        width: 77%;
        display: flex;
        flex-direction: column;
        padding: 20px 25px;
        justify-content: space-between;
        align-self: stretch;
        gap: 15px;
        border-radius: 15px;
        background-color: white;

        .title {
            font-size: 13px;
            font-weight: 600;
        }

        .section_item {
            display: flex;
            justify-content: space-between;
        }

        .item {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 400;
            gap: 5px;

            .text_gray {
                color: #787878;
            }

        }

    }

    .card {
        width: 27%;
        display: flex;
        flex-direction: column;
        align-self: stretch;
        padding: 20px 25px;
        gap: 15px;
        border-radius: 15px;
        background-color: white;

        .title {
            font-size: 14px;
            font-weight: 600;
        }

        .section_item {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }

        .item {
            width: 100%;
            display: flex;
            flex-direction: column;
            font-size: 14px;
            font-weight: 400;
            gap: 5px;

            .text_gray {
                display: flex;
                align-items: center;
                color: #787878;
            }

        }

        .confirm_button {
            gap: 5px;
            padding: 13px 25px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: $white;

            &_disable {
                background: #787878;

            }

            .icon {
                path {
                    fill: $white;
                }
            }

        }
    }
}

.container_mobile {
    flex-wrap: wrap;

    .settings {
        width: 100%;
    }

    .card {
        width: 100%;
        flex-direction: row;
        // flex-wrap: wrap;

        .confirm_button {
            width: 50%;
            margin-left: auto;

            .icon {
                height: 100%;
                width: auto;
            }
        }
    }
}