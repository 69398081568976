@import '../../../../../../styles/index.scss';


.item {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 120px;

        &_summary {
                position: absolute;
                width: 100%;
                display: flex;
                align-items: center;
                gap: 16px;
                height: 80px;
                background: $white;
                padding: 15px 50px;
                z-index: 2;
                border-radius: 15px;

                &_icon {
                        margin-left: auto;
                        transform: rotate(90deg);
                        transition: transform 0.3s ease;
                        cursor: pointer;
                }

                &[open] {
                        background: black;
                }

                &_name {
                        font-weight: $font-weight-bolder;
                        font-size: 17px;
                        line-height: 17px;
                        margin-right: 10px;
                }
        }

        summary~* {
                z-index: 1;
        }

        &[open] {
                margin-bottom: 100px;

                summary {
                        border-bottom: 1px solid #1F1F1F33;
                        border-radius: 15px 15px 0px 0px;

                        .item_summary_icon {
                                transform: rotate(-90deg);
                                transition: transform 0.3s ease;


                        }
                }

                summary~* {
                        transform: translateY(80px);
                        animation: open .15s ease-in-out;
                }

                @keyframes open {
                        0% {
                                transform: translateY(0px);
                                opacity: 0;
                        }

                        100% {
                                transform: translateY(80px);
                                opacity: 1;
                        }
                }
        }

        .item_container {
                display: flex;
                justify-content: space-between;
                padding: 28px 50px;
                background: $white;
                border-radius: 0px 0px 15px 15px;
                margin-bottom: 30px;
                flex-wrap: wrap;

                .color_wrapper {
                        margin-top: 15px;
                        width: 20%;
                        min-width: 115px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                }

                .info_cell {
                        width: 100%;

                        .info_title {
                                display: flex;
                                align-items: center;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                margin-bottom: 10px;

                                svg {
                                        margin-left: 20px;
                                        cursor: pointer;
                                }
                        }

                        .info_value {
                                text-align: justify;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: $middleGray;
                        }
                }

                .title_wrapper {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        margin-right: 10px;

                        .title {
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                        }
                }

                .name_wrapper {
                        width: 22%;
                        margin-top: 15px;
                        padding-right: 20px;
                }

                .description_wrapper {
                        width: 32%;
                        margin-top: 15px;
                        padding-right: 20px;
                }

                .main_block_action_wrapper {
                        width: fit-content;
                        margin-top: 15px;
                }

                .duration_wrapper {
                        width: 48%;
                        margin-top: 20px;
                }

                .price_wrapper {
                        width: 48%;
                        margin-top: 20px;
                }

                .main_block_action_card {
                        width: 49.5%;
                        padding: 20px 25px;
                        border-radius: 15px;
                        background: #F4F4F4;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 35px;
                        cursor: pointer;
                        transform: translate(-5px, 0);

                        &:last-child {
                                margin-left: 2px;
                        }

                        .main_block_action_card_info {
                                .main_icon {
                                        margin: auto 30px auto 0px;

                                }

                                .action_card_info_title {
                                        font-weight: 700;
                                        font-size: 20px;
                                        line-height: 25px;
                                }

                                .action_card_info_value {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 17px;
                                        color: $middleGray;
                                }
                        }

                        .main_block_action_card_action {
                                .arrow_right {
                                        border: solid $black;
                                        height: 3px;
                                        border-width: 0 3px 3px 0;
                                        display: inline-block;
                                        padding: 3px;
                                        transform: rotate(-45deg);
                                        align-self: center;
                                        justify-self: flex-end;
                                }
                        }

                        &:hover {
                                filter: brightness(0.8);

                                .main_block_action_card_info {
                                        .action_card_info_title {}

                                        .action_card_info_value {}
                                }

                                .main_block_action_card_action {
                                        .arrow_right {
                                                border: solid $white;
                                                border-width: 0 3px 3px 0;
                                        }
                                }
                        }
                }

                .disable_container {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 35px;
                        align-items: center;

                        .delete_button {
                                display: flex;
                                align-items: center;
                                padding: 13px 40px 13px 30px;
                                border-radius: 10px;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                color: $red;
                                background: $lightRed;

                                svg {
                                        width: 20px;
                                        height: 20px;
                                        margin-right: 10px;
                                }
                        }

                        .enable_button {
                                padding: 13px 40px;
                                border-radius: 10px;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                background: $gradientBlue;
                                color: white;
                                margin: 5px;
                        }
                }

                .enable_container {
                        justify-content: flex-end;

                        .title_wrapper {
                                display: none;
                        }

                        .enable_button {
                                display: block;
                        }

                        .delete_button {

                                z-index: -100;
                        }
                }
        }

}

.item_mobile {
        .item_summary {
                padding: 15px 40px 15px 20px;
        }

        .item_container {
                padding: 28px 20px;

                .color_wrapper {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        margin-bottom: 30px;
                        margin-top: 30px;
                }

                .name_wrapper {
                        width: 100%;
                        padding-right: 0px;
                }

                .description_wrapper {
                        width: 100%;
                        padding-right: 0px;
                        margin-top: 30px;

                }

                .main_block_action_wrapper {
                        width: 100%;
                        margin-top: 30px;
                }

                .duration_wrapper {
                        width: 100%;
                }

                .price_wrapper {
                        width: 100%;
                }

                .info_cell {
                        width: 100%;

                        .info_title {

                                svg {
                                        margin-left: auto;
                                        cursor: pointer;
                                }
                        }

                        .info_value {}
                }

                .main_block_action_card {
                        width: 100%;
                        transform: translate(0, 0);
                        margin-top: 0px;
                        margin-bottom: 10px;
                }

                .main_block_action_card_info {
                        .action_card_info_title {}

                        .action_card_info_value {
                                word-wrap: break-word;
                                padding: 0px 20px 0px 0px;
                        }
                }

                .disable_container {
                        .title_wrapper {
                                display: none;
                        }

                        .delete_button {
                                padding: 16px 40px 16px 30px;
                                width: 100%;
                        }

                        .enable_button {
                                padding: 17px 40px 17px 30px;
                                width: 100%;
                        }
                }
        }
}

details summary::-webkit-details-marker {
        display: none;
}