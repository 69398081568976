@import '../../../../../../styles/index.scss';

.shared_workers_modal {
  display: flex;
  flex-direction: column;
  max-height: 80dvh;
  overflow: auto;
  padding-bottom: 20px;

  .list_container {
    background: $white;
    border-radius: 15px;

    .selector_header {
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      padding: 25px;
    }
  }
}

.shared_workers_modal_mobile {

}
