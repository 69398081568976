@import '../../../../styles/index';

.selector_wrapper {
  //display: flex;
  position: relative;
  align-items: center;

  //margin-left: 20px;
  .input_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
    padding-left: 10px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .selector_container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;

    min-width: 150px;
    max-width: 300px;

    background-color: $mediumGray;

    border: none;
    border-radius: 10px;
    padding: 12px 25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    &_required {
      border: 1px $red !important;
    }

    &:hover {
      -webkit-filter: brightness(0.8);
      filter: brightness(0.8);
    }

    .value {
      font-weight: 700;
      font-size: 14px;
      margin-right: 10px;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .arrow {
      border: 5px solid transparent;
      border-bottom: 5px solid $black;
      margin-top: 0;
      margin-bottom: 5px;
    }
  }

  .dropdown__menu {
    width: 100%;
    min-width: 150px;
    position: absolute;
    z-index: 10000;
    top: 100%;
    transition: transform 0.1s ease-out;
    transform-origin: top;
    border-radius: 10px;
    visibility: inherit !important;

    ::-webkit-scrollbar-track {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.selector_wrapper_close {
  .selector_container {
    .arrow {
      margin-top: 5px;
      margin-bottom: 0;
      transform: rotate(180deg);
    }
  }

  .dropdown__menu {
    transform: scaleY(0);
  }
}