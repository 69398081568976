@import '../../../../../../styles/index';

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $black;
  position: relative;

  .welcome {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: 200ms ease-in;

    .welcome_preview {
      position: absolute;
      top: 0;
      left: 0;
      background: red;
      width: 100%;
      height: 100%;
    }

    .welcome_container {
      position: absolute;
      top: 0;
      left: 0;
      background: green;
      width: 100%;
      height: 100%;
    }
  }

  .welcome_active {
    left: 0;
    .welcome_preview {
      animation-name: showAndHide;
      animation-duration: 5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      justify-content: space-between;
    }
    .welcome_container {
      animation-name: showAndStill;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-delay: 5s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      justify-content: space-between;
      transform: scaleX(0) scaleY(0);
    }
  }

  .welcome_prev {
    left: -100%;
  }

  .welcome_next {
    left: 100%;
  }

  .time {
    position: absolute;
    transition: 200ms ease-in;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .time_first_preview {
      position: absolute;
      top: 0;
      left: 0;
      background: blue;
      width: 100%;
      height: 100%;
    }

    .time_second_preview {
      position: absolute;
      top: 0;
      left: 0;
      background: pink;
      width: 100%;
      height: 100%;
    }

    .time_container {
      position: absolute;
      top: 0;
      left: 0;
      background: yellow;
      width: 100%;
      height: 100%;
    }
  }

  .time_active {
    left: 0;
    .time_first_preview {
      animation-name: showAndHide;
      animation-duration: 5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      justify-content: space-between;
    }
    .time_second_preview {
      animation-name: showAndHide;
      animation-duration: 5s;
      animation-delay: 5s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      justify-content: space-between;
      transform: scaleX(0) scaleY(0);
    }
    .time_container {
      animation-name: showAndStill;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-delay: 10s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
      justify-content: space-between;
      transform: scaleX(0) scaleY(0);
    }
  }

  .time_prev {
    left: -100%;
  }

  .time_next {
    left: 100%;
  }

  .break {
    position: absolute;
    transition: 200ms ease-in;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .break_preview {
      position: absolute;
      top: 0;
      left: 0;
      background: violet;
      width: 100%;
      height: 100%;
    }

    .break_container {
      position: absolute;
      top: 0;
      left: 0;
      background: red;
      width: 100%;
      height: 100%;
    }
  }

  .break_active {
    left: 0;
  }

  .break_prev {
    left: -100%;
  }

  .break_next {
    left: 100%;
  }
}

@keyframes showAndStill {
  0% {
    transform: scaleX(0) scaleY(0);
  }
  100% {
    transform: scaleX(100%) scaleY(100%);
  }
}

@keyframes showAndHide {
  0% {
    transform: scaleX(0) scaleY(0);
  }
  20% {
    transform: scaleX(100%) scaleY(100%);
  }
  70% {
    transform: scaleX(100%) scaleY(100%);
  }
  100% {
    transform: scaleX(0) scaleY(0);
    display: none;
  }
}

//.container__inner {
//  display: flex;
//  justify-content: center;
//  width: 100%;
//  height: 100%;
//  align-items: center;
//  overflow: hidden;
//  background: $black;
//  position: relative;
//
//  .salon_open_time_wrapper {
//    width: 100%;
//    height: 100%;
//    .salon_open_time_container_preview {
//      transition: 200ms ease-in;
//      position: absolute;
//      top: 0;
//      left: 100%;
//      width: 100%;
//      height: 100%;
//      z-index: 1;
//      color: red;
//    }
//  }
//
//  .salon_open_time_wrapper_active {
//    .salon_open_time_container_preview {
//      left: 0;
//    }
//  }
//
//  .salon_open_time_wrapper_reload {
//    .salon_open_time_container_preview {
//      //left: 0;
//    }
//  }
//
//  .welcome__wrapper {
//    width: 100%;
//    height: 100%;
//
//    .welcome__container_preview {
//      position: absolute;
//      top: 0;
//      left: 100%;
//      width: 100%;
//      height: 100%;
//      z-index: 2;
//      background: #1F1F1F;
//      animation-name: showAndHide;
//      animation-duration: 4s;
//      animation-iteration-count: 1;
//      animation-fill-mode: forwards;
//      animation-timing-function: ease-in-out;
//      justify-content: space-between;
//      display: flex;
//      flex-direction: column;
//      align-items: center;
//
//      .logo_container {
//        width: 50%;
//        max-width: 300px;
//
//        .logo {
//          width: 100%;
//          display: block;
//          margin-bottom: 50px;
//        }
//      }
//
//      h1 {
//        color: $white;
//        font-size: 7vw;
//        font-weight: 700;
//        font-style: normal;
//        text-transform: uppercase;
//        letter-spacing: -0.4vw;
//
//        & > span {
//          color: $gradientBlue;
//          font-size: 7vw;
//          font-weight: 700;
//          font-style: normal;
//          text-transform: uppercase;
//          letter-spacing: -0.4vw;
//        }
//      }
//    }
//
//    .welcome__container {
//      position: absolute;
//      top: 0;
//      left: -100%;
//      width: 100%;
//      height: 100%;
//      z-index: 1;
//      transition: 20s ease-in;
//      animation-name: showAndStillOnePreview;
//      animation-duration: 5s;
//      animation-iteration-count: 1;
//      //animation-delay: 4s;
//      animation-fill-mode: forwards;
//      animation-timing-function: ease-in-out;
//      justify-content: space-between;
//      //transform: scaleX(0) scaleY(0);
//      display: flex;
//      flex-direction: column;
//      align-items: center;
//
//      .logo_container {
//        width: 50%;
//        max-width: 300px;
//
//        .logo {
//          width: 100%;
//          display: block;
//          margin-bottom: 50px;
//        }
//      }
//
//      h1 {
//        color: $white;
//        font-size: 7vw;
//        font-weight: 700;
//        font-style: normal;
//        text-transform: uppercase;
//        letter-spacing: -0.4vw;
//
//        & > span {
//          color: $gradientBlue;
//          font-size: 7vw;
//          font-weight: 700;
//          font-style: normal;
//          text-transform: uppercase;
//          letter-spacing: -0.4vw;
//        }
//      }
//    }
//  }
//
//  .welcome__wrapper_active {
//    .welcome__container_preview {
//      transform: scaleX(100%) scaleY(100%);
//      left: 0;
//    }
//    .welcome__container {
//      left: 0;
//    }
//  }
//
//  .welcome__wrapper_reload {
//    .welcome__container_preview {
//      width: 0;
//      height: 0;
//      display: none;
//    }
//    .welcome__container {
//      animation-name: showAndHide;
//    }
//  }
//}
//
//@keyframes showAndHide {
//  0% {
//    transform: scaleX(0) scaleY(0);
//  }
//  20% {
//    transform: scaleX(100%) scaleY(100%);
//  }
//  70% {
//    transform: scaleX(100%) scaleY(100%);
//  }
//  100% {
//    transform: scaleX(0) scaleY(0);
//    display: none;
//  }
//}
//
//@keyframes showAndStill {
//  0% {
//    z-index: 1;
//    transform: scaleX(0) scaleY(0);
//  }
//  100% {
//    z-index: 1;
//    transform: scaleX(100%) scaleY(100%);
//  }
//}
//
//@keyframes showAndStillOnePreview {
//  0% {
//    z-index: 1;
//    transform: scaleX(0) scaleY(0);
//  }
//  80% {
//    z-index: 1;
//    transform: scaleX(0) scaleY(0);
//  }
//  100% {
//    z-index: 1;
//    transform: scaleX(100%) scaleY(100%);
//  }
//}
//
//@keyframes showAndStillTwoPreview {
//  0% {
//    z-index: 1;
//    transform: scaleX(0) scaleY(0);
//  }
//  80% {
//    z-index: 1;
//    transform: scaleX(0) scaleY(0);
//  }
//  100% {
//    z-index: 1;
//    transform: scaleX(100%) scaleY(100%);
//  }
//}
