@import '../../../../../../styles/index';

.second_info_step_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20% 0 10%;

  h2 {
    font-weight: 700;
    line-height: 46px;
    font-size: 3vw;
    font-style: normal;
    text-transform: uppercase;
    color: $lightYellow;
    margin-bottom: 60px;

    .blue_inject {
      color: $gradientBlue;
      font-size: 3vw;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
    }

    .gold_inject {
      color: $gold;
      font-size: 3vw;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
    }
  }

  .selector_menu_container {
    margin-bottom: 65px;

    .field_title {
      font-weight: 400;
      font-size: 20px;
      line-height: 33px;
      color: $lightYellow;
    }

    .workers_container {
      margin-bottom: 40px;
      .field_container {
        display: flex;
        //align-items: center;

        .field_button {
          min-width: 36px;
          min-height: 36px;
          width: 36px;
          height: 36px;
          background: #F2EBFF;
          border-radius: 10px;
          margin-top: 5px;
          margin-left: 5px;

          svg {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}
