@import '../../../../styles/index.scss';

.notification_list_container {
  padding: 40px 60px 80px;

  .filters_container {
    display: flex;
    justify-content: space-between;
    padding: 50px 10px 20px 10px;

    .button_wrapper {
      margin: auto 0;
      display: flex;
      justify-content: space-between;

      .confirm_button {
        padding: 8px 16px;
        border-radius: 5px;
        font-weight: 600;
        font-size: 12px;
        line-height: 17px;
        background: #1f1f1f;
        color: $gradientLightBlue;
        width: fit-content;
        margin: 0;
        position: relative;

        .novita {
          position: absolute;
          top: 5px;
          right: 5px;
          width: 8px;
          height: 8px;
          background: $red;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &:hover {
          background-color: #1f1f1f96;
        }

      }
    }
  }
}

.notification_list_container_mobile {
  padding: 30px 15px;

  .filters_container {
    flex-wrap: wrap;

    .button_wrapper {
      padding-bottom: 20px;
    }
  }
}