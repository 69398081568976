@import '../../../../styles/index.scss';
$modal-height: 80dvh;
$mobile-modal-height: 70dvh;

.modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-height: $modal-height;
  padding: 5px 20px 25px;
  width: 100%;

  .body {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;

    .date_info {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .date {
        display: flex;
        align-items: center;
        gap: 5px;
      }

      p {
        color: $textGray;
        font-size: 13px;
      }
    }
  }

  .subtitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    &_voucher {
      color: red
    }
  }

  .normal {
    font-weight: 500;
  }

  &_mbl {
    min-height: $mobile-modal-height;
  }
}

.tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}

.divider {
  height: 1px;
  background-color: $lightgray;
  width: 100%;
}