@import '../../../styles/index.scss';

.modal__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 111;
  overflow: visible;

  &::-webkit-scrollbar-track {
    border-bottom-right-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }
}

.is_error {
  z-index: 112;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $white;
  border-radius: 20px;
  box-shadow: 0px 0px 23px 7px rgba(46, 51, 56, 0.25);
  width: 100%;


  .modal__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 9px 9px 9px 15px;

    .modal__title {
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      color: $black;
      display: flex;
      flex-direction: column;

      &_bold {
        font-size: 16px;
        line-height: 25px;
        font-weight: 500;
      }
    }

    .close_button {
      cursor: pointer;
      margin-left: 20px;
    }
  }
}

@-webkit-keyframes action {
  0% {
    transform: translate(0%, 0%);
  }

  100% {
    transform: translate(0%, -100%);
  }
}

@keyframes action {
  0% {
    transform: translate(0%, 0%);
  }

  100% {
    transform: translate(0%, -100%);
  }
}

.modal_mobile {
  width: 100% !important;
  min-width: 100% !important;
  top: 100%;
  left: 0;
  transform: translate(0%, -100%);
  border-radius: 20px 20px 0px 0px;
  -webkit-animation: action 0.3s linear;
  animation: action 0.3s linear;

  .modal__header {
    .modal__title {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: $black;
      display: flex;
      flex-direction: column;

      &_bold {
        font-size: 12px;
        line-height: 25px;
        font-weight: 500;
      }
    }
  }
}