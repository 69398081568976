@import '../../../../../../styles/index.scss';

.delete_cell_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-right: 60px;

  .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    background: $gradientRed;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
}

.day_container {
  display: flex;

  .day_value {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $middleGray;

    &:first-letter {
      text-transform: capitalize;
    }
  }
}

.empty_card {
  padding: 60px 23px 0px 56px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $middleGray;
}

.action_footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-bottom: 1px solid $borderColor;
  padding-top: 5px;
  padding-bottom: 20px;

  .action_button {
    width: fit-content;
    display: flex;
    align-items: center;
    color: $gradientBlue;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-right: 60px;
    cursor: pointer;

    svg {
      width: 19px;
      height: 19px;
      margin-left: 9px;
    }
  }
}

.time_cell {
  display: flex;
  flex-direction: column;

  .time_cell_value {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $middleGray;

    &:first-child {
      padding-bottom: 5px;
    }
  }
}

.badge_payment,
.badge_payment_active {
  width: fit-content;
  padding: 13px 20px;
  border-radius: 5px;
  background-color: #F4F4F4;
  color: #1f1f1f;
  text-align: center;

  &_active {
    background-color: #65ce8f56;
    color: #65ce8f;
    font-weight: 600;
  }
}