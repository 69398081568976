@import '../../../../../../styles/index.scss';

.service_workers_modal_container {
  padding: 40px 60px 80px;
  overflow: hidden;

  .name_cell_container {
    display: flex;
    align-items: center;
    margin-top: 45px;
    margin-bottom: 35px;
    margin-left: 30px;

    .client_info {
      margin-left: 20px;

      .name {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 5px;
      }

      .description {
        font-weight: 400;
        font-size: 14px;
        color: $middleGray;
      }
    }
  }

  .time_cards_container {
    padding-right: 60px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: fit-content;

    .time_card_container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 12%;
      //min-width: 150px;
      //max-width: 150px;
      height: 240px;
      background: $white;
      border-radius: 10px;
      padding: 17px;
      margin-bottom: 20px;
      margin-right: 10px;
      cursor: pointer;

      .day_header {
        .day_name {
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          padding-bottom: 20px;

          &:first-letter {
            text-transform: capitalize;
          }
        }

        .time_value {
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          color: $middleGray;
          padding-bottom: 3px;
        }

        .close_value {
          display: none;
        }
      }

      .time_action {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        align-self: end;
        cursor: pointer;
      }

      &:hover {
        filter: brightness(0.8)
      }


    }

    .active {
      background: $gradientBlue;

      .time_action {
        color: $white;
      }

      .day_header {
        .day_name {
          color: $white;
        }

        .time_value {
          color: $white;
        }
      }
    }

    .close {
      background: $red;

      .time_action {
        color: $white;
      }

      .day_header {
        .day_name {
          color: $white;
        }

        .time_value {
          display: none;
        }

        .close_value {
          display: block;
          font-weight: 400;
          font-size: 17px;
          line-height: 20px;
          color: $white;
        }
      }
    }
  }

  .list_container {
    .list_item_container {
      width: 100%;
      display: flex;
      height: fit-content;
      padding: 40px;
      background: $white;
      border-radius: 14px;
      margin-bottom: 25px;
      justify-content: space-between;
      cursor: pointer;


      .info_block {
        display: flex;

        .description_block {
          margin-left: 20px;

          .item_title {
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 5px;
          }

          .item_description {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $middleGray;
          }
        }
      }

      .arrow {
        margin: auto 0px;
      }

      &:hover {
        filter: brightness(0.8);

        .info_block {
          .description_block {
            .item_title {}

            .item_description {}
          }
        }

        .arrow_right {
          border: solid $white;
          border-width: 0 3px 3px 0;
        }
      }
    }
  }
}

.service_workers_modal_container_mobile {
  padding: 30px 18px 30px;


  .name_cell_container {

    .client_info {

      .name {}

      .description {}
    }
  }

  .time_cards_container {

    .time_card_container {

      .day_header {
        .day_name {
          &:first-letter {}
        }

        .time_value {}

        .close_value {}
      }

      .time_action {}

      &:hover {}
    }

    .active {
      .time_action {}

      .day_header {
        .day_name {}

        .time_value {}
      }
    }

    .close {
      .time_action {}

      .day_header {
        .day_name {}

        .time_value {}

        .close_value {}
      }
    }
  }

  .list_container {
    .list_item_container {
      padding: 40px 40px 40px 10px;

      .info_block {

        .description_block {
          .item_title {}

          .item_description {}
        }
      }

      .arrow {}

      &:hover {
        .info_block {
          .description_block {
            .item_title {}

            .item_description {}
          }
        }

        .arrow_right {}
      }
    }
  }

  //.time_cards_container {
  //  margin-top: 30px;
  //
  //  .time_card_container {
  //    width: 100%;
  //    height: 105px;
  //    flex-direction: row;
  //  }
  //}
}