@import '../../../../../styles/index';

.worker_selector {
    display: flex;
    flex-direction: column;
    align-items: center;

    .settings_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        padding-right: 8px;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    svg {
        width: 22px;
        height: 22px;
        cursor: pointer;
    }

    .body {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        max-height: calc(90vh - 200px);
        width: 100%;
        background: $white;
        display: flex;
        flex-direction: column;
        padding: 25px;
        //border-radius: 15px;
        gap: 20px;

        .close_btn {
            padding: 9px 0px 9px 9px;

            .icon {
                width: 13px;
                height: 13px;
            }
        }

        .text {
            font-size: 14px;
            font-weight: 500;
        }

        /* not working
      .header {
        display: 'flex';
        flex-direction: 'row';
        gap: '10px';
        justify-content: 'space-between';

        .user {
          display: 'flex';
          flex-direction: 'row';
          gap: '5px';
          align-items: 'center';
        }
      } */

        .link {
            .icon {
                width: 15px;
                height: 15px;

                path {
                    fill: $gradientBlue;
                }
            }

            .ltext {
                font-size: 14px;
                font-weight: 600;
                color: $gradientBlue ;
            }

            &:hover {
                .ltext {
                    color: #797fe8 !important;
                    font-weight: 700;
                    //text-decoration: underline;
                }
            }
        }

        .link_disabled {
            cursor: block;

            .ltext {
                font-size: 14px;
                font-weight: 600;
                color: $textGray;
            }

        }

    }

    .footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 30px;

        .menu_btn {
            background: $gradientLightBlue;
            border-radius: 10px;
            padding: 20px 40px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $gradientBlue;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;

            &:hover {
                background: $gradientBlue;
                color: $white;
            }
        }

    }
}

.linear_progress_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 50px;

    &_text {
        text-align: start;
        font-size: 14px;
    }
}