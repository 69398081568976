@import '../../styles/index.scss';

.header_wrapper {
  height: 65px;
  width: 100%;
  border-bottom: 1px solid $borderColor;
  display: flex;
  justify-content: space-between;
  background-color: $white;

  .menu_container {
    width: fit-content;
    height: fit-content;
    padding-left: 20px;
    padding-top: 13px;
    z-index: 3;

    svg {
      cursor: pointer;
      height: 40px;
      width: 40px;
      fill: $black;
    }
  }

  .info_container {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    z-index: 3;

    .notification_icon_container {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      width: 30px;
      height: 30px;
      background-color: $black;
      border-radius: 10px;
      margin-right: 20px;

      svg {
        height: 25px;
        width: 25px;
        fill: $white;
      }
    }

    .notification_icon_container_mobile {
      display: none;
    }

    .salon_wrapper {
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      padding-right: 20px;

      .salon_container {
        width: fit-content;
        background-color: $black;
        border-radius: 14px;
        padding: 8px;
        display: flex;
        align-items: center;
        cursor: pointer;

        .salon_name {
          margin-left: 10px;
          color: $white;
          font-weight: 700;
          font-size: 15px;
          line-height: 20px;
        }

        .arrow {
          border: 5px solid transparent;
          border-bottom: 5px solid $white;
          margin-top: 0;
          margin-bottom: 5px;
          margin-left: 10px;
        }
      }

      .dropdown__menu {
        width: fit-content;
        min-width: 200px;
        position: absolute;
        z-index: 10;
        top: 100%;
        right: 20px;
        overflow: hidden;
        transition: transform 0.3s ease-out;
        transform: scaleY(1);
        transform-origin: top;
        display: flex;
        flex-direction: column;
        align-items: center;

        .exit_button_container {
          width: 100%;
          height: 60px;
          background-color: $white;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid $borderColor;
          border-top: none;
          border-radius: 0 0 10px 10px;
        }
      }
    }

    .salon_wrapper_closed {
      .salon_container {
        .arrow {
          margin-top: 5px;
          margin-bottom: 0;
          transform: rotate(180deg);
        }
      }

      .dropdown__menu {
        transform: scaleY(0);
      }
    }
  }
}

.header_wrapper_mobile {
  background: transparent;
  border: none;

  .info_container {
    .salon_wrapper {
      .salon_container {
        padding: 5px 7px;
        border-radius: 10px;

        .salon_name {
          display: none;
        }
      }
    }

    .notification_icon_container {
      display: none;
    }

    .notification_icon_container_mobile {
      display: block;
      margin-right: 25px;

      svg {
        height: 25px;
        width: 25px;
        fill: $black;
      }
    }
  }
}

.header_wrapper_calendar {
  .info_container {
    display: none;
  }
}

.header_wrapper_calendar_mobile {
  height: 130px;
}
