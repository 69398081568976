@import '../../../../../../../styles/index';

.salon_picker {
  padding: 80px 0 40px 0;
  display: flex;
  flex-direction: column;

  .selector_container {

  }

  .checkbox_container {
    padding-top: 20px;
    color: white;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 18px;
    line-height: 17px;

    .closures_checkbox {
      width: 27px;
      height: 27px;
      accent-color: #787878;
      margin-left: 15px;

      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 27px;
        height: 27px;
        position: relative;
        content: '';
        visibility: visible;
        background: #787878;
      }

      &:checked:after {
        z-index: -1;
      }
    }
  }
}
