@import '../../styles/index.scss';

.sidebar__wrapper {
  min-width: 240px;
  width: 240px;
  overflow: hidden;
  transition: 200ms ease-in;
  height: 100%;

  .sidebar__inner {
    padding-right: 10px;
    padding-top: 36px;
    height: 100%;
    background-color: $white;

    .sidebar__logo {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background-color: $white;
      margin-bottom: 21px;

    }

    .navItem {
      display: flex;
      justify-content: center;
      align-items: center;
      width: fit-content;
      max-width: 100%;

      gap: 10px;
      padding: 5px;
      border-radius: 4px;
      margin: 0 auto;
      text-align: center;

      &_exit {
        margin-top: 50px;
        width: 100%;
        padding: 12px;
        border-radius: 8px;
        cursor: pointer;

        &:hover {
          background: $lightgray2;
        }
      }
    }

    .nav-bar {
      height: 100%;
      overflow: auto;
      background-color: $white;

      ul {
        li {
          list-style: none;
        }
      }
    }
  }
}

.sidebar__wrapper__mobile {
  min-width: 100%;
  width: 100%;
  position: absolute;
  z-index: 30;
  bottom: 0;
  left: 0;

  .sidebar__inner {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: rgb(120, 120, 120, 0.6);
    mix-blend-mode: multiply;
    padding-right: 0;
    transition: 200ms ease-in;
    height: 100vh;


    .nav-bar {
      padding-top: 40px;
      border-radius: 20px 20px 0 0;
      height: fit-content;
      max-height: 100dvh;
      padding-bottom: 110px;
      transition: 200ms ease-in;
    }
  }
}

.sidebar__wrapper__close {
  min-width: 80px;
  width: 80px;
}

.sidebar__wrapper__close_mobile {
  z-index: -1;

  .sidebar__inner {
    background: transparent;

    .nav-bar {
      max-height: 0;
      padding: 0;
    }
  }
}