@import '../../../../../../styles/index.scss';

.item {
        position: relative;
        width: 100%;
        height: 100%;
        margin-bottom: 120px;

        &_summary {
                position: absolute;
                width: 100%;
                display: flex;
                align-items: center;
                gap: 16px;
                height: 80px;
                background: $white;
                padding: 15px 50px;
                z-index: 2;
                border-radius: 15px;

                &_icon {
                        margin-left: auto;
                        transform: rotate(90deg);
                        transition: transform 0.3s ease;
                        cursor: pointer;
                }

                &[open] {
                        background: black;
                }

                &_name {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 17px;
                        margin-right: 10px;
                }
        }

        summary~* {
                z-index: 1;
        }



        &[open] {
                margin-bottom: 100px;

                summary {
                        border-bottom: 1px solid #1F1F1F33;
                        border-radius: 15px 15px 0px 0px;

                        .item_summary_icon {
                                transform: rotate(-90deg);
                                transition: transform 0.3s ease;

                        }
                }

                summary~* {
                        transform: translateY(80px);
                        animation: open .15s ease-in-out;
                }

                @keyframes open {
                        0% {
                                transform: translateY(0px);
                                opacity: 0;
                        }

                        100% {
                                transform: translateY(80px);
                                opacity: 1;
                        }
                }
        }

        .item_container {
                display: flex;
                justify-content: space-between;
                padding: 28px 50px;
                background: $white;
                border-radius: 0px 0px 15px 15px;
                margin-bottom: 30px;
                flex-wrap: wrap;

                .avatar_wrapper {
                        width: auto;
                        margin-right: 38px;
                        min-width: 115px;

                        .avatar_container {
                                width: fit-content;
                                height: fit-content;
                                position: relative;

                                svg {
                                        position: absolute;
                                        top: 75%;
                                        left: 75%;
                                        z-index: 10;
                                        width: 25px;
                                        height: 25px;
                                        cursor: pointer;
                                }
                        }
                }

                .worker_info_cell {
                        width: 100%;

                        .info_title {
                                display: flex;
                                align-items: center;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                margin-bottom: 10px;

                                svg {
                                        margin-left: 20px;
                                        cursor: pointer;
                                }
                        }

                        .info_value {
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 17px;
                                color: $middleGray;
                        }
                }

                .title_wrapper {
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        margin-right: 10px;

                        .title {
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                        }
                }

                .worker_name_wrapper {
                        width: 22%;
                        margin-top: 15px;
                        padding-right: 20px;
                }

                .worker_description_wrapper {
                        width: 32%;
                        margin-top: 15px;
                        padding-right: 20px;
                        margin-right: auto;

                }

                .main_block_action_wrapper {
                        width: fit-content;
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                }

                .main_block_payment_wrapper {
                        width: fit-content;
                        margin-top: 15px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 8px;
                        padding-right: 20px;

                        .badge_pagamenti {
                                width: 100%;
                                padding: 13px 20px;
                                border-radius: 5px;
                                background-color: #F4F4F4;
                                color: #1f1f1f;
                                text-align: center;

                                &_all {
                                        background-color: #65CE8F;
                                        color: #fff;
                                }

                                &_some {
                                        background-color: #A5CE00;
                                        color: #fff;
                                }

                        }

                        .link {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;
                                cursor: pointer;
                                gap: 5px;
                                align-items: center;

                                .icon {
                                        width: 15px;
                                        height: 15px;

                                        path {
                                                fill: $gradientBlue;
                                        }
                                }

                                .ltext {
                                        font-size: 15px;
                                        font-weight: 500;
                                        color: $gradientBlue;
                                        text-align: left;
                                }

                                &:hover {
                                        .ltext {
                                                color: #6f73d0 !important;
                                                //text-decoration: underline;
                                        }
                                }

                        }
                }

                .main_block_action_card {
                        width: 49.5%;
                        padding: 20px 25px;
                        border-radius: 15px;
                        background: #F4F4F4;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-top: 35px;
                        transform: translate(-5px, 0);
                        cursor: pointer;

                        .main_block_action_card_info {
                                .action_card_info_title {
                                        font-weight: 700;
                                        font-size: 20px;
                                        line-height: 25px;
                                }

                                .action_card_info_value {
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 17px;
                                        color: $middleGray;
                                }
                        }

                        .main_block_action_card_action {
                                .arrow_right {
                                        border: solid $black;
                                        height: 3px;
                                        border-width: 0 3px 3px 0;
                                        display: inline-block;
                                        padding: 3px;
                                        transform: rotate(-45deg);
                                        align-self: center;
                                        justify-self: flex-end;
                                }
                        }

                        &:hover {
                                filter: brightness(0.8);

                                .main_block_action_card_info {
                                        .action_card_info_title {}

                                        .action_card_info_value {}
                                }

                                .main_block_action_card_action {
                                        .arrow_right {
                                                border-width: 0 3px 3px 0;
                                        }
                                }
                        }
                }

                .worker_disable_container {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 35px;
                        align-items: center;

                        .delete_button {
                                display: flex;
                                align-items: center;
                                padding: 13px 40px;
                                border-radius: 10px;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                color: $red;
                                background: $lightRed;

                                svg {
                                        width: 20px;
                                        height: 20px;
                                        margin-right: 10px;
                                }
                        }

                        .enable_button {
                                display: none;

                                padding: 13px 40px;
                                border-radius: 10px;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                                background: $gradientBlue;
                                color: $white;
                                margin-left: 5px;

                                &:hover {
                                        filter: brightness(0.8)
                                }
                        }
                }

                .worker_enable_container {
                        justify-content: flex-end;

                        .title_wrapper {
                                display: none;
                        }

                        .enable_button {
                                display: block;
                        }

                        .delete_button {
                                display: none;
                        }
                }
        }

}

.item_mobile {
        .item_summary {
                padding: 15px 40px 15px 20px;
        }

        .item_container {
                padding: 35px 22px;

                .avatar_wrapper {
                        width: 100%;
                }



                .worker_name_wrapper {
                        width: 100%;
                        padding-right: 0px;
                        margin-top: 30px;

                        .info_title {

                                svg {
                                        margin-left: auto;
                                        cursor: pointer;
                                }
                        }
                }

                .worker_description_wrapper {
                        width: 100%;
                        padding-right: 0px;
                        margin-top: 30px;

                        .info_title {

                                svg {
                                        margin-left: auto;
                                        cursor: pointer;
                                }
                        }
                }

                .main_block_action_wrapper {
                        width: 100%;
                        align-items: start;
                        margin-bottom: 30px;
                        margin-top: 30px;

                }

                .main_block_action_card {
                        width: 100%;
                        transform: translate(0, 0);
                        margin-top: 0px;
                        margin-bottom: 10px;
                }

                .worker_disable_container {
                        .title_wrapper {
                                display: none;
                        }

                        .delete_button {
                                width: 100%;
                                padding: 16px 40px;

                        }

                        .enable_button {
                                width: 100%;
                                padding: 17px 40px;
                        }
                }
        }
}