@import 'src/styles/index.scss';

.payment_container {
        padding: 40px 60px 80px;

        &_body {
                display: flex;
                flex-direction: column;
                gap: 50px;
                margin-top: 50px;


                &_warning {
                        padding: 40px 60px 80px;
                        position: relative;
                        display: flex;
                        width: min(100%, 1400px);
                        padding: 25px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 30px;
                        color: $white;
                        border-radius: 15px;
                        background: $red;

                        h4 {
                                color: $white;
                        }

                        .title {
                                display: flex;
                                padding: 0px 10px;
                                align-items: flex-start;
                                gap: 10px;
                                align-self: stretch;
                        }

                        .content {
                                display: flex;
                                justify-content: space-between;
                                width: 100%;

                                .text {
                                        max-width: 740px;
                                        min-height: 51px;
                                        font-size: 14px;
                                        font-style: normal;
                                        font-weight: 500;
                                        line-height: 17px;
                                        letter-spacing: 0.014px;
                                }

                                .button_paid {
                                        background-color: $white;
                                        padding: 17px 38px;
                                        border-radius: 10px;

                                        h4 {
                                                font-weight: 500;
                                                color: $red;
                                                word-wrap: break-word;
                                        }
                                }

                        }

                }

                .button {
                        background: $white;
                        border-radius: 14px;
                        display: flex;
                        flex-direction: column;
                        padding: 10px;
                        gap: 5px;
                        bottom: 0px;
                        min-width: 80px;
                        width: 100%;

                        &_main,
                        &_container {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 10px;

                                &:hover {
                                        filter: brightness(0.8);
                                }
                        }

                        &_main {
                                height: min(20vh, 50px);
                                width: min(100%, 400px);
                                background: $red;
                                color: $white;
                        }

                        &_container {
                                width: min(100%, 150px);
                                height: min(20vh, 50px);
                                ;
                                background: $gradientLightBlue;
                                color: $gradientBlue;
                        }
                }

                hr {
                        width: 100%;
                        border-color: rgb($lightgray, 0.16);
                }

                h5 {
                        font-weight: $font-weight-small;
                        margin-bottom: 5px;
                        font-size: 14px;
                }

                &_wrapper {
                        padding: 40px 60px 80px;
                        position: relative;
                        display: flex;
                        width: min(100%, 1400px);
                        padding: 25px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 30px;
                        border-radius: 15px;
                        background: $white;
                }

                .packs {

                        padding-top: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 10px;
                }

                .table {
                        width: 100%;

                        &_title {
                                padding: 0px 0 10px 0px;
                                font-weight: 700;
                                font-size: 14px;
                                line-height: 17px;
                        }

                        &_row {
                                padding: 0px 0 20px 0px;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: $middleGray;
                        }
                }
        }
}

.payment_link {
        display: 'flex' !important;
        justify-content: 'center';
        align-items: 'flex-start';
        gap: '2px';
        font-size: 14px;

        cursor: pointer;

        .icon {
                padding-bottom: '2px';
                fill: $gradientBlue;
        }

        .text {
                color: $gradientBlue;
        }

        &:hover {
                .icon {
                        padding-bottom: '2px';
                        fill: $blueHover;
                }

                .text {
                        display: 'flex';
                        justify-content: 'flex-start';
                        color: $blueHover;
                        font-weight: 700;
                        text-decoration: underline;
                }
        }

        &_disabled {
                cursor: auto;

                .icon {
                        padding-bottom: '2px';
                        fill: $textGray;
                }

                .text {
                        color: $textGray;
                        text-decoration: line-through;
                }

        }


}

.payment_container_is_bill {
        background-color: $clearGray;
        overflow-y: auto;
        height: 100vh;
}

.payment_container_mobile {
        padding: 30px 18px 13px;

        .payment_container_body {
                gap: 50px;
                display: flex;
                flex-direction: column;
                padding: 30px 0px 30px;

                &_warning {

                        .content {
                                display: flex;
                                flex-direction: column;
                                gap: 20px;
                        }
                }

                hr {
                        width: 100%;
                        border-color: rgb($lightgray, 0.16);
                }

                h5 {
                        font-weight: $font-weight-small;
                        margin-bottom: 5px;
                        font-size: 14px;
                }
        }

}