@import '../../../../styles/index.scss';

.salon_container {
  padding: 40px 60px 80px;

  .salon_card {
    margin-top: 35px;
    border-radius: 10px;
    background: $white;
    display: flex;
    flex-direction: column;

    .form_header {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      padding: 30px 50px;
      border-bottom: 1px solid $borderColor;
    }

    .form_container {
      display: flex;

      .main_block_wrapper {
        width: 100%;
        display: flex;
        padding: 30px 47px 25px 32px;
        flex-direction: column;

        .main_block_row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .block_row_title {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 20px;
            padding-left: 10px;
            display: flex;
            align-items: center;
          }

          .selector_title_wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            margin-right: 10px;
            margin-left: 10px;

            .selector_title {
              font-weight: 700;
              font-size: 14px;
              line-height: 17px;
            }
          }

          .appointments_count_wrapper {
            display: flex;
            justify-content: flex-end;
            min-width: 90px;
          }

          .switcher_container {
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
          }
        }

        .main_block_row_hide {
          display: none;
        }

        .salon_actions {
          display: flex;
          justify-content: flex-end;
          width: 100%;
          padding-top: 40px;

          .confirm_button {
            padding: 13px 40px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: white;
            margin-left: 5px;
          }
        }
      }

      .image_block {
        width: 380px;
        min-width: 340px;
        padding: 30px 0 30px 47px;
        border-right: 1px solid $borderColor;

        .form_title_wrapper {
          display: flex;

          .form_title {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 20px;
          }
        }

        .cover_image_wrapper {
          height: 250px;
          width: 200px;
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          background-blend-mode: color, normal;
          border-radius: 10px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          .cover_image_action {
            align-self: flex-end;
            margin: 17px;
            cursor: pointer;
          }

          .avatar_container {
            position: absolute;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            height: -webkit-fit-content;
            height: -moz-fit-content;
            height: fit-content;
            top: 100%;
            left: 50%;
            transform: translate(-50%, -50%);

            svg {
              position: absolute;
              top: 75%;
              left: 75%;
              z-index: 10;
              width: 25px;
              height: 25px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.salon_container_mobile {
  padding: 30px 18px 0 13px;

  .salon_card {
    .form_header {
      display: none;
    }

    .form_container {
      flex-direction: column;

      .main_block_wrapper {
        width: 100%;
        padding: 70px 20px 20px 20px;

        .main_block_row {
          flex-wrap: wrap;

          .block_row_title {
            width: 100%;
          }

          .appointments_count_wrapper {
            width: 100%;
          }

          .selector_title_wrapper {
            width: 100%;
          }

          .switcher_container {
            width: 100%;
          }
        }

        .salon_actions {
          .confirm_button {
            width: 100%;
            height: 70px;
          }
        }
      }

      .image_block {
        width: 100%;
        border-right: none;
        padding: 30px 20px 0 20px;

        .cover_image_wrapper {
          height: 280px;
          width: 100%;
        }
      }
    }
  }
}