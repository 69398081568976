@import '../../../../styles/index.scss';

.container {
    padding: 0px 0px 16px 0px;
    background-color: $black;
    margin: 20px 0px;
    border-radius: 15px;
    width: 100%;
    height: fit-content;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .cover {
        width: 100%;
        height: auto;
        object-fit: cover;
        border-radius: 15px 15px 0px 0px;
    }

    .header {
        width: 100%;
        padding: 0px 16px;



        p {
            color: $white;
            font-family: 'Poppins';
            line-height: 17px;
            font-size: 14px;
            font-weight: normal;

        }



    }

    .action {
        width: 100%;
        padding: 0px 16px;

        .confirm_button {
            width: 100%;
            margin-top: auto;
            padding: 13px 40px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: white;
            margin-left: 0;
        }
    }


    &_desktop {
        background-color: $white;

        .header {
            padding: 16px;
            padding-bottom: 0px;

            .h1 {
                margin-top: 25px;
                color: $black;
                font-family: 'Poppins';
                font-size: 20px;
                font-weight: 700;
                line-height: 29px;
            }

            p {
                color: $black;
            }
        }

        .action {
            .confirm_button {
                margin-left: auto;
                width: fit-content;
            }
        }

    }


}