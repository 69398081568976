@import '../../../../styles/index';

.selector_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .dropdown__menu {
    max-height: 220px;
    width: 100%;
    position: absolute;
    z-index: 100000;
    top: 100%;
    left: 0;
    overflow-y: auto;
    transition: transform 0.3s ease-out;
    transform: scaleY(1);
    transform-origin: top;
    &::-webkit-scrollbar-track{
      border-radius: 0px 10px 10px 0px;
      
    }
  }
}

.selector_wrapper_close {
  .dropdown__menu {
    transform: scaleY(0);
  }
}

.selector_wrapper_mobile {

  .dropdown__menu {}
}