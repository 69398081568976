@import '../../../../styles/index.scss';

.blocker_container {
  padding: 40px 60px 0 40px;
  height: fit-content;
  overflow: hidden;
}

.blocker_container_mobile {
  padding: 60px 16px 0 16px;
}

.header_wrapper_mobile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 150px;

  svg {
    height: 25px;
    width: 25px;
    fill: $black;
  }
}

.container {
  width: 100%;
  height: fit-content;
  box-shadow: 0px 5px 10px -10px #6C7982;
  border-radius: 14px;
  background-color: $white;
  margin: 20px 0px;
  padding: 25px 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;

  .header {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 100%;
    height: fit-content;

    .h1 {
      font-size: 20px;
      font-weight: 700;
      line-height: 25px;
      padding-bottom: 10px;
    }

    .description {
      width: 90%;
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: $black;

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        font-family: 'Poppins';
        color: $black;

      }
    }

  }


  .confirm_button {
    margin-top: auto;
    width: fit-content;
    padding: 13px 40px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background: $gradientBlue;
    color: white;
    margin-left: 0;
  }

}