@import '../../styles/index.scss';

.modal__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 40px;
  font-family: 'Poppins';
  gap: 24px;
  position: relative;


  .close_btn {
    position: absolute;
    top: 12px;
    right: 16px;
    cursor: pointer;
  }

  .head {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
    align-items: center;

    .title {
      text-align: center;
      font-size: 20px;
      font-weight: 800;
    }
  }

  .label {
    text-align: center;
    padding: 0px 15px;
    font-size: 17px;
    font-weight: 200;
    line-height: 1.2;
  }

  .footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .btn {
      display: flex;
      padding: 10px 30px 0px 30px;
      width: 100%;
      justify-content: center;
    }
  }

  h2 {
    text-align: center;
    line-height: 1.2;
  }

  .modal__actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    &>button:first-child {
      margin-bottom: 15px;
    }
  }
}

.modal__content_mobile {

  .body {

    .head {
      gap: 5px;

      .title {
        font-size: 16px;
        font-weight: 850;
      }
    }

    .label {
      font-size: 14px;
      padding: 0px 10px;

    }
  }
}