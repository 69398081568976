@import '../../../../../../styles/index.scss';

.closures_modal_wrapper {
  padding: 20px 30px;
  max-height: 90dvh;
  overflow: auto;

  .date_selector_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 10px;
    padding-top: 20px;
  }

  .actions_container {
    display: flex;
    justify-content: flex-end;

    .confirm_button {
      width: 100%;
      padding: 18px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $gradientBlue;
      color: white;
    }
  }
}


