@import '../../../../../../../styles/index';

.picker_input_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;

  .input_label_container {
    display: flex;
    flex-direction: column;
    width: fit-content;


    .input_wrapper {
      display: flex;
      height: 100%;
      width: fit-content;

      .field__container {
        position: relative;
        width: fit-content;

        .field__value {
          width: 139px;
          height: 77px;
          align-items: center;
          border: none;
          border-radius: 10px;
          background-color: $middleGray;
          font-weight: 400;
          font-size: 25px;
          line-height: 17px;
          text-align: center;
          color: $white;
          cursor: pointer;
        }


        input:focus {
          //outline: 1px solid $blueHover;
          outline: none;
        }

        input:required {
          outline: 1px solid $red;
        }

        .error_message {
          position: absolute;
          top: 100%;
          left: 0;
          color: $red;
          padding-left: 5px;
          padding-top: 5px;
        }
      }
    }

    .input_title {
      font-weight: 400;
      font-size: 20px;
      line-height: 33px;
      margin-bottom: 10px;
      color: $lightYellow;
    }


    &:has(input:disabled) {
      opacity: 0.4;

      .input_title {
        opacity: 0.4;
      }
    }
  }
}
