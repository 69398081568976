@import '../../../../styles/index.scss';

.sharing_container {
  padding: 40px 60px 80px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .worker_add_button {
      cursor: pointer;
    }

  }
  .sharing_container_list {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
    .sharing_item {
      display: flex;
      justify-content: space-between;
      padding: 15px;
      border-bottom: 1px solid $borderColor;
      .item_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
      svg {
        width: 23px;
        height: 23px;
        cursor: pointer;
      }
      .download_button {
        text-decoration: inherit;
        color: inherit;
        svg {
          width: 23px;
          height: 23px;
          cursor: pointer;
        }
      }
    }
  }
}

.sharing_container_mobile {
  padding-left: 15px;
  padding-right: 15px;
}