@import '../../../../styles/index.scss';

.home_container {
  padding: 40px 60px 0 40px;
  height: fit-content;
  overflow: hidden;
}

.home_container_mobile {
  padding: 40px 16px 0 16px;
}

.header_wrapper_mobile {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-right: 150px;
  svg {
    height: 25px;
    width: 25px;
    fill: $black;
  }
}
