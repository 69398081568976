@import '../../../../styles/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');


.container {
    width: 100%;
    height: fit-content;
    box-shadow: 0px 5px 10px -10px #6C7982;
    border-radius: 14px;
    background-color: $white;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    .header {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 25px 25px;

        .text {
            font-size: 14px;
            font-weight: 500;
            font-family: "Poppins";
            line-height: 17px;
        }

        .h1 {
            font-size: 20px;
            font-weight: 700;
            line-height: 25px;
            font-family: "Poppins";

        }

        .progress_section {
            display: flex;
            gap: 15px;

            .progress {
                width: 30%;
                height: 10px;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;

        .step_row {
            padding: 15px 25px;
            border-top: 1px solid #7878784d;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 15px;
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            font-family: "Poppins";
            height: fit-content;

            .text {
                font-size: 14px;
                font-weight: 300;
                font-family: "Poppins";
                line-height: 17px;
            }

            .confirm_button {
                padding: 13px 40px;
                border-radius: 10px;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                background: $gradientBlue;
                color: white;
                margin-left: auto;

                :hover {
                    background-color: $blueHover !important;
                }

                .icon {
                    margin-right: 5px;
                    min-width: 17px;
                    min-height: 17px;
                    max-width: 17px;
                    max-height: 17px;

                    path {
                        fill: white;
                    }
                }
            }
        }

        .form {
            padding: 15px 56px;
            display: flex;
        }

    }

}

.container_mbl {
    .body {
        .step_row {
            padding: 15px 20px;

            .confirm_button {
                margin-left: 0;
            }
        }
    }
}