@import '../../../../styles/index.scss';

.statistic_container {
  padding: 10px 60px 80px;
}

@media (max-width: 767px) {
  .statistic_container {
    padding: 0; /* Rimuovi completamente il padding sui dispositivi mobili */
  }
}