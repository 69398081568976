@import 'styles/index.scss';

$modal-height: 80dvh;
$mobile-modal-height: 90dvh;

.account_modal {
    width: 100%;
    max-height: $modal-height;
    overflow: auto;

    .body {
        display: flex;
        flex-direction: column;
        gap: 25px;
        padding: 20px 30px 40px;
        max-height: calc($modal-height - 200px);
        overflow-y: auto;
        scroll-behavior: smooth;
        height: fit-content;
    }

    .footer {
        padding: 15px 50px;
    }

    .actions_container {
        display: flex;
        justify-content: flex-end;

        .confirm_button {
            width: 100%;
            padding: 18px 40px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: white;
        }
    }
}