@import '../../../../../../styles/index';

.welcome_step_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .welcome_step_content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 450px;
    .welcome_step_description {
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      //letter-spacing: -0.029em;
      color: $lightYellow;
      padding-bottom: 60px;
    }
  }
}

.welcome_step_first_preview {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  .logo_container {
    width: 50%;
    max-width: 300px;

    .logo {
      width: 100%;
      display: block;
      margin-bottom: 50px;
    }
  }

  h1 {
    color: $lightYellow;
    font-size: 7vw;
    font-weight: 700;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: -0.4vw;

    & > span {
      color: $gradientBlue;
      font-size: 7vw;
      font-weight: 700;
      font-style: normal;
      text-transform: uppercase;
      letter-spacing: -0.4vw;
    }
  }
}