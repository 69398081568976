@import '../../../../styles/index.scss';

.worker_avatar_modal_container {
  display: flex;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  max-height: 90dvh;
  overflow: auto;

  .button_block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    .confirm_button {
      display: none;
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $gradientBlue;
      color: white;
      margin-left: 5px;
    }
    .return_button {
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $gradientBlue;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  .confirm_active {
    .confirm_button {
      display: block;
    }
  }
}
