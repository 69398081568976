@import '../../../../../../styles/index.scss';

$modal-height: 80dvh;
$mobile-modal-height: 90dvh;

.worker_extra_time_container {
  display: flex;
  flex-direction: column;
  max-height: $modal-height;
  overflow: auto;

  .closures_modal_wrapper {
    .body {
      overflow-y: auto;
      max-height: calc($modal-height - 200px);
      scroll-behavior: smooth;
      padding: 30px 30px;

      .worker_name {
        padding-left: 0px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding-bottom: 10px;
      }

      .modal_description {
        display: none;
        padding-left: 30px;
        padding-top: 10px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
      }

      .time_selector_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 27px;
        padding-top: 20px;
      }

      .week_day_selector_container {
        width: 100%;
      }

      .block {
        display: flex;
        width: 100%;
        gap: 30px;
        margin-top: 50px;

        .range_picker_container {
          width: 55%;

          .date_selector_title {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 10px;
          }

        }

        .time_selector_item_container_full_day {
          display: flex;
        }

        .time_selector_wrapper {
          display: flex;
          flex-direction: column;

          .time_selector_title {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 27px;
            padding-top: 0px;
          }

          .time_selector_container {
            display: flex;

            .time_selector_item_container {
              width: 100%;
              display: flex;
              align-items: center;

              .time_selector_item {
                width: calc(49% - 20px);

                &:first-child {
                  margin-right: 30px;
                }
              }

              .time_selector_item_delete {
                display: flex;
                align-items: flex-end;
                width: calc(49% + 47px);

                .svg {
                  min-width: 37px;
                  min-height: 37px;
                  padding: 7px;
                  margin-left: 10px;
                  border-radius: 10px;
                  background: $lightRed;
                  cursor: pointer;
                }
              }

              .time_selector_extra_field {
                display: flex;
              }
            }

            .time_selector_item_container_all_fields {
              display: flex;
            }

            .time_selector_item_container_full_day {
              display: none;
            }
          }

          .time_selector_container_full_day {
            .time_selector_item_container_all_fields {
              display: none;
            }

            .time_selector_item_container_full_day {
              display: flex;
            }
          }

          .time_selector_add_new_field {
            display: block;
            font-weight: 700;
            font-size: 11px;
            line-height: 16px;
            color: $gradientBlue;
            cursor: pointer;
            padding-top: 15px;
            opacity: 1;
          }

          .time_selector_add_new_field_hide {
            opacity: 0;
          }
        }
      }


      .workers__menu {
        width: 100%;
        position: relative;

        .workers__menu_title {
          padding: 20px 0px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
        }
      }


      .comment {
        width: 100%;

        &__title {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 27px;
          padding-top: 20px;

          span {

            padding-left: 0px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            padding-bottom: 10px;
          }
        }

        .input {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }

    .footer {
      .actions_container {
        display: flex;
        justify-content: flex-end;
        //margin: 20px;
        padding: 15px 40px 15px;

        .confirm_button {
          padding: 13px 40px;
          border-radius: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          background: $gradientBlue;
          color: white;
          margin-left: 5px;

        }
      }
    }
  }
}

.worker_extra_time_container_one_day {
  .worker_name {
    display: none;
  }

  .modal_description {
    display: block;
  }

  .closures_modal_wrapper {
    .body {
      .block {
        margin-top: 10px;

        .workers__menu {
          width: 100%;
        }

        .range_picker_container {
          display: none;
        }
      }

      .week_day_selector_container {
        display: none;
      }
    }
  }
}

.worker_extra_time_container_mobile {
  max-height: $mobile-modal-height ;

  .closures_modal_wrapper {
    .body {
      max-height: calc($mobile-modal-height - 200px);

      .block {
        flex-wrap: wrap;


        .range_picker_container {
          width: 100%;
        }

        .time_selector_wrapper {

          .time_selector_container {
            display: flex;
            flex-wrap: wrap;

            .time_selector_item_container {
              width: 100%;
              display: flex;
              padding-bottom: 10px;

              &:first-child {
                margin-bottom: 0;
              }

              .time_selector_item {
                width: 40%;
              }

              .time_selector_item_delete {
                width: calc(40% + 47px);
              }

              .time_selector_extra_field {
                display: flex;
              }
            }

            .time_selector_item_container_all_fields {
              display: flex;
            }

            .time_selector_item_container_full_day {
              display: none;
            }
          }

          .time_selector_container_full_day {
            .time_selector_item_container_all_fields {
              display: none;
            }

            .time_selector_item_container_full_day {
              display: flex;
            }
          }
        }
      }

      .workers__menu {
        width: 100%;
      }

    }

    .footer {

      .actions_container {
        padding: 10px 15px 10px;

        .confirm_button {
          width: 100%;
          margin-left: 0px;
          padding: 22px 40px;

        }
      }
    }
  }
}

.confirm_body {
  margin-top: 15px;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: column;
  width: 100%;
  font-size: 14px;
  line-height: 17px;

  p {
    font-size: 14px;
    line-height: 17px;
  }
}