@import 'styles/index.scss';

.account_container {
    position: relative;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    .item_summary {
        // position: absolute;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 16px;
        height: 80px;
        background: $white;
        padding: 15px 50px;
        z-index: 2;
        border-radius: 15px;

        &_icon {
            margin-left: auto;
            transform: rotate(90deg);
            transition: transform 0.3s ease;
            cursor: pointer;
        }

        &[open] {
            background: black;
        }

        &_name {
            font-weight: $font-weight-bolder;
            font-size: 17px;
            line-height: 17px;
            margin-right: 10px;
        }
    }

    summary~* {
        z-index: 1;
    }

    &[open] {
        summary {
            border-bottom: 1px solid #1F1F1F33;
            border-radius: 15px 15px 0px 0px;

            .item_summary_icon {
                transform: rotate(-90deg);
                transition: transform 0.3s ease;


            }
        }

        summary~* {
            transform: translateY(0px);
            animation: open .15s ease-in-out;
        }

        @keyframes open {
            0% {
                transform: translateY(0px);
                opacity: 0;
            }

            100% {
                transform: translateY(0px);
                opacity: 1;
            }
        }
    }

    .item_container {
        display: flex;
        justify-content: space-between;
        padding: 28px 50px;
        background: $white;
        border-radius: 0px 0px 15px 15px;
        flex-wrap: wrap;
        margin-bottom: 10px;

        .duration_wrapper,
        .price_wrapper {
            width: 25%;
            margin-top: 20px;

            .info_cell {
                width: 100%;

                .info_title {
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 10px;

                    svg {
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }

                .info_value {
                    text-align: justify;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: $middleGray;
                }
            }


        }

        .main_block_action_wrapper {
            width: fit-content;
            margin-top: 15px;

            .title_wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 15px;


                .title {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                }
            }

            .delete_button {
                display: flex;
                align-items: center;
                padding: 13px 40px 13px 30px;
                border-radius: 10px;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                color: $red;
                background: $lightRed;
                width: 100%;

                svg {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                }
            }

            .primary_button {
                width: 100%;
                padding: 13px 40px;
                border-radius: 10px;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                background: $gradientBlue;
                color: white;
            }
        }

        .permessi_wrapper {
            width: 100%;
            margin-top: 20px;

            .info_cell {
                width: 100%;

                .info_title {
                    display: flex;
                    align-items: center;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 10px;

                    svg {
                        margin-left: 20px;
                        cursor: pointer;
                    }
                }

                .info_value {
                    text-align: justify;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: $middleGray;
                }
            }

            .permessi_container {
                display: flex;
                gap: 10px;
                width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
                scrollbar-width: thin;

                &::-webkit-scrollbar {
                    margin-top: 2px;
                    height: 6px; // Larghezza della scrollbar
                }

                .sections_container {
                    display: flex;
                    gap: 10px;
                    padding: 5px 10px;
                    border-radius: 10px;
                    background: $gradientLightBlue; //#E7E8FE
                    align-items: center;
                    width: fit-content;

                    .section {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: $gradientBlue;
                    }

                    .subsections_list {
                        display: flex;
                        gap: 10px;
                        padding: 5px 10px;
                        border-radius: 10px;
                        background: rgb($white, 0.5);
                        align-items: center;
                        width: fit-content;

                        .subsection {
                            width: fit-content;
                            white-space: nowrap;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                        }

                    }
                }
            }
        }

    }

}

.account_container_mbl {
    .item_summary {
        padding: 15px 40px 15px 20px;
    }

    .item_container {
        padding: 28px 20px;

        .duration_wrapper,
        .price_wrapper {
            width: 45%;

            .info_cell {

                .info_title {

                    svg {
                        margin-left: auto;
                        cursor: pointer;
                    }
                }

            }
        }


        .main_block_action_wrapper {
            width: 100%;
            margin-top: 30px;

            .delete_button {
                padding: 16px 40px 16px 30px;
                width: 100%;
            }
        }

        .permessi_wrapper {
            .permessi_container {
                flex-direction: column;
            }
        }

    }
}

details summary::-webkit-details-marker {
    display: none;
}