@import '../../../../../styles/index.scss';

* {
    font-family: 'Poppins';
    font-weight: 300;
}


.container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: fit-content;
    width: 100%;
    padding-bottom: 50px;

    .img_background {
        position: absolute;
        right: 5%;
        top: 15%;
        height: 55%;
        // width: 60%; //mobile
    }

    .text_section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        width: 40%;
        margin: 60px 0px 30px;
        margin-left: 15%;

        .h1 {
            font-weight: 800;
            font-size: 52px;
            font-family: 'Antonio';
            line-height: 1.5;

        }

        .p {
            font-weight: 300;
            font-size: 15px;
            line-height: 1.5;
        }

    }

    .steps_wrapper {
        margin: 15px 0px 30px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        gap: 5%;
        flex-wrap: wrap;


        .point_step {
            display: flex;
            flex-direction: column;
            width: 45%;
            font-size: 19px;
            margin-top: 15px;
            line-height: 22px;
            font-weight: 400;
            gap: 5px;
            background-color: #FCB734; //$gradientLightBlue;
            border-radius: 20px;
            font-family: Antonio;
            padding: 13px;

            .text_special {
                width: 100%;
                font-size: 54px;
                //color: #FCB734;
                font-family: Antonio;
                font-weight: 700;
            }
        }

    }

    .commission_table {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 13px;
        width: 100%;

        .row {
            display: flex;
            border: 2px solid $gradientBlue;
            width: 100%;
            border-radius: 15px;
            gap: 10px;

            .left {
                display: flex;
                flex-direction: column;
                width: 50%;
                font-size: 14px;
                padding: 15px;
                gap: 15px;
            }

            .right {
                display: flex;
                flex-direction: column;
                font-size: 14px;
                padding: 15px;
                width: 45%;
                gap: 10px
            }
        }


    }

    .actions_section {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 10px;
        position: relative;
        width: 40%;
        margin-left: 15%;

        svg {
            margin-right: auto;
            width: fit-content;
        }

        .buttons {
            margin-right: 20px;

            .confirm_button {
                padding: 13px 30px;
                border-radius: 10px;

                background-color: $gradientBlue;
            }

            .secondary_button {
                margin-left: 5px;
                padding: 13px 30px;
                background-color: black;
            }
        }


    }
}

.container_mobile {
    .img_background {
        display: none;
    }

    .text_section {
        margin: 20px 10px 0px;
        width: fit-content;

        .h1 {
            font-size: 43px;
        }
    }

    .steps_wrapper {
        .point_step {
            .text_special {
                font-size: 45px;
            }
        }
    }

    .actions_section {
        margin: 20px 10px 0px;
        width: auto;
        justify-content: flex-end;

        .buttons {
            margin-right: 0;
            display: flex;
        }

    }

}