@import 'src/styles/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&family=Lato:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:regular,bold,italic,light,medium&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:regular,bold,italic,light,medium&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:regular,bold,italic,light,medium&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:regular,bold,italic,light,medium&subset=latin,latin-ext');

.pack_container {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: $lightgray2;
        padding: 20px;
        border-radius: 15px;
        width: 247px;
        min-height: 247px;

        &_title {
                font-family: "Barlow Condensed";
                font-weight: 900;
                font-size: 35px;
                letter-spacing: -0.5px;
                text-transform: uppercase;
                word-wrap: break-word;
        }

        &_body {
                display: flex;
                flex-direction: column;
                gap: 6px;

                h4 {
                        font-weight: $font-weight-small;
                        margin-bottom: 5px;
                        font-size: 14px;
                }

                h3 {
                        margin-top: 30px;
                        font-weight: 400;
                }
        }
}

.isActive {
        background: $gradientBlue;

        * {
                color: $white;
        }
}