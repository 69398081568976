@import '../../../styles/index.scss';

.field__wrapper {
  margin-bottom: 10px;
  position: relative;
  width: 100%;
  font-family: 'Poppins';


  .checkbox_as_radio_wrapper {
    display: flex;
    align-items: center;

    .radio__label {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: $middleGray;
      margin-left: 8px;
    }

    .field__radio {
      height: 22px;
      width: 22px;
      -webkit-appearance: none;
      appearance: none;
      background-color: #fff;
      margin: 0;
      border: 1px solid $middleGray;
      border-radius: 50%;
      cursor: pointer;
      position: relative;

      &:checked::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: '';
        display: inline-block;
        width: 66.6%;
        height: 66.6%;
        border-radius: 50%;
        background: $gradientBlue;
      }
    }
  }

  .field__textarea,
  .input-group {
    width: 100%;
    margin-bottom: 10px;
    color: $black;
    padding: 10px;
  }

  .input-group {
    display: flex;
    align-items: center;
  }

  .field__textarea,
  .field__input {
    width: 100%;
    border: none;
    color: $black;
    font-size: 14px;
    font-family: 'Poppins';

    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      color: $textGray;
    }
  }

  .field__textarea {
    resize: none;
    min-height: 50px;
    padding-top: 15px !important;
    max-width: 100%
  }

  .field__textarea:disabled {
    resize: none;
  }

  .field__label {
    display: block;
    margin-bottom: 17px;
    padding-left: 10px;
    font-weight: 700;
    font-size: 14px;
  }

  .error {
    display: flex;
    color: $red;
    font-size: 12px;
    line-height: 1.2;
    padding-left: 10px;
    margin-bottom: 10px;
  }

  .error-textarea,
  .error-radio,
  .error-field {
    color: $red;
  }
}

.full-height {
  height: 100%;

  .field__textarea {
    height: calc(100% - 24px);
  }
}

input[type="password" i] {
  color: $black !important;
}

input[type="password" i].error-field {
  color: $red !important;
}

.input-group {
  display: flex;
  padding-right: 10px;
}

.show-pwd-icon {
  cursor: pointer;

  path {
    stroke: $middleGray;
  }
}

.input_icon {
  width: 17px;
  height: 17px;

  .icon {
    display: inline-block;
    max-width: 17px;
    max-height: 17px;

    path {
      fill: $middleGray;
    }
  }
}

.show-pwd-icon__red {
  path {
    stroke: $red;
  }
}

.field__textarea:disabled,
.field__input:disabled {
  background-color: black;

  .input_title {
    opacity: 0.4;
  }
}