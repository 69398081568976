@import '../../../../../../styles/index';

.extra_time_selector {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;

  .settings_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding-right: 8px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  svg {
    width: 22px;
    height: 22px;
    cursor: pointer;
  }

  .dropdown__menu {
    height: fit-content;
    width: fit-content;
    position: absolute;
    z-index: 10000;
    top: 150%;
    right: 0;
    overflow: hidden;
    transition: transform 0.3s ease-out;
    transform-origin: top;
    border-radius: 10px;

    .dropdown__item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin: 20px 5px;

      &_time {
        display: flex;
        gap: 10px;
        margin-top: 10px;
      }

      .text {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-top: 5px;
      }

    }


    .dropdown__menu_inner_list {
      height: fit-content;
      background: $white;
      border-radius: 10px;
      padding: 10px;



      .dropdown__menu_item {
        width: fit-content;
        background: $gradientLightBlue;
        border-radius: 10px;
        padding: 17px 38px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        color: $gradientBlue;
        margin-bottom: 10px;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          background: $gradientBlue;
          color: $white;
        }
      }
    }
  }
}

.extra_time_selector_close {
  .dropdown__menu {
    transform: scaleY(0);
  }
}