@import "../../../styles/index";

.buttons_container {
  display: flex;
  width: fit-content;

  .filter_button {
    background: $gradientLightBlue;
    width: fit-content;
    display: flex;
    border-radius: 5px;
    padding: 8px 16px;
    margin-right: 10px;

    &:hover {
      background: $gradientLightBlue;
      filter: brightness(0.8);
    }

    .button_value {
      background: $gradientBlue;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 700;
      font-size: 11px;
      line-height: 16px;
      text-transform: none;
    }
  }

  .active_filter_button {
    background: $gradientBlue;

    &:hover {
      background: $gradientBlue;
      filter: brightness(0.8);
    }

    .button_value {
      background: $white;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.buttons_container_mobile {
  padding: 0px 15px;

  .filter_button {

    &:hover {}

    .button_value {}
  }

  .active_filter_button {

    &:hover {}

    .button_value {}
  }
}