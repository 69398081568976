@import '../../../../styles/index';

.info_value {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.info_task_modal_mobile {
  padding: 30px;
  max-height: 90dvh;
  overflow: auto;

  .time_wrapper {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    margin-top: 15px;
  }

  .special_icons_container {
    display: flex;
    padding-top: 10px;

    .info_icon {
      padding: 2px 3px;
      margin-right: 5px;
      background: $white;
      border-radius: 50%;
      cursor: pointer;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .info_block {
    padding-top: 30px;

    .info_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      padding-bottom: 5px;
    }

    .info_value {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $middleGray;
    }
  }

  .bad_section {
    display: flex;
    gap: 8px;
    padding: 12px 8px;
    border-radius: 4px;
    background: rgb($red, 0.25);
    border: 1px solid $red;
    border-left: 4px solid $red;
    color: $red;

    .info_cell {
      flex-direction: column;
      justify-content: flex-start;

      padding: 0;
      margin: 0;

      .info_value {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $red;
        display: flex;
        align-items: center;
      }
    }
  }

  .edit_action {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background: $gradientBlue;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    padding-top: 15px;
    cursor: pointer;
  }

  .client_info_block {
    padding-top: 10px;

    .client_info_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      padding-bottom: 10px;
      padding-top: 30px;
    }

    .info_cell {
      width: 200px;
      padding-bottom: 15px;
      padding-top: 10px;

      .info_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 10px;
      }

      .info_value {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $middleGray;
      }

      .info_icons_container {
        display: flex;

      }
    }
  }

  .delete_button {
    width: 100%;
    padding: 13px 40px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background: $red;
    color: $white;
    margin-top: 25px;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

}

.info_task_modal {
  width: 100%;
  padding: 10px 30px 30px;
  max-height: 90vh;
  overflow: auto;

  .time_wrapper {
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    margin-top: 15px;
  }

  .special_icons_container {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  .info_block {
    padding-top: 30px;

    .info_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      padding-bottom: 5px;
    }

    .info_value {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $middleGray;
    }
  }

  .bad_section {
    display: flex;
    gap: 8px;
    padding: 12px 8px;
    margin: 8px 0 16px;
    border-radius: 4px;
    background: rgb($red, 0.25);
    border: 1px solid $red;
    border-left: 4px solid $red;

    .bad_value {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: $red;
    }
  }

  .edit_action {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background: $gradientBlue;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    padding-top: 15px;
    cursor: pointer;
  }

  .client_info_block {
    padding-top: 10px;

    .client_info_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      padding-bottom: 10px;
      padding-top: 30px;
    }

    .info_cell {
      width: 200px;
      padding-bottom: 15px;
      padding-top: 10px;

      .info_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 10px;
      }

      .info_value {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $middleGray;
      }

      .info_icons_container {
        display: flex;

        .info_icon {
          padding: 12px;
          background: $mediumGray;
          margin-right: 20px;
          border-radius: 10px;
          cursor: pointer;

          svg {
            width: 25px;
            height: 25px;
          }
        }
      }
    }
  }

  .delete_button {
    max-width: 500px;
    width: 100%;
    padding: 25px 40px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background: $red;
    color: $white;
    margin: 0px auto;

    &:hover {
      background: $red;
    }

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

}