@import '../../../../styles/index.scss';

.salon_container {
  padding: 40px 60px 80px;

  .salon_card {
    margin-top: 35px;
    border-radius: 10px;
    background: $white;
    display: flex;
    flex-direction: column;

    .form_container {
      padding: 20px 40px;
      display: flex;
      flex-direction: column;
    }

    .security_actions {
      display: flex;
      justify-content: flex-end;
      padding: 0 40px 40px 40px;

      .confirm_button {
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        color: white;
        margin-left: 5px;
      }
    }
  }

  .keyword_info {
    margin-top: 30px;
    margin-left: 15px;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 10px;
    }

    .description {
      max-width: 420px;
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: $middleGray;
      margin-bottom: 40px;
    }
  }

  .keyword_form {
    padding: 30px 40px;

    .keyword_actions {
      display: flex;
      justify-content: flex-end;
      padding-top: 40px;

      .confirm_button {
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        color: white;
        margin-left: 5px;
      }
    }
  }
}

.salon_container_mobile {
  padding: 30px 18px 10px 13px;

  .salon_card {
    .form_container {
      padding: 29px 15px 15px 15px;
    }

    .security_actions {
      padding: 5px 10px 50px 10px;

      .confirm_button {
        width: 100%;
        height: 70px;
      }
    }
  }

  .keyword_form {
    padding: 29px 15px 15px 15px;

    .keyword_actions {
      .confirm_button {
        width: 100%;
        height: 70px;
      }
    }
  }
}