@import '../../../styles/index';

.modal__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25px 34px;

  .title {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    padding-top: 20px;
    text-align: center;
  }

  h3 {
    text-align: center;
    line-height: 1.2;
  }

  .modal__actions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: 25px;

    .confirm_button {
      width: 48%;
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $gradientBlue;
      color: $white;
      margin-left: 5px;

      &:hover {
        filter: brightness(0.8)
      }
    }

    .return_button {
      width: 48%;
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $black;
    }
  }
}