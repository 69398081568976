@import '../../../../styles/index';

.color_selector_wrapper {
  position: relative;
  width: fit-content;
  min-width: 150px;
  max-width: 200px;

  .title_container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    margin-right: 10px;

    .input_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }
  }

  .selector_container {
    width: 100%;
    border-radius: 10px;
    padding: 7px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    background: $lightgray2;


    .value {
      width: 37px;
      height: 37px;
      border-radius: 50%;
    }
  }

  .dropdown__menu {
    width: 100%;
    min-width: 150px;
    max-width: 300px;
    position: absolute;
    z-index: 10000;
    top: 100%;
    overflow: hidden;
    transition: transform 0.3s ease-out;
    transform-origin: top;
    border-radius: 10px;

    ::-webkit-scrollbar-track {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.color_selector_wrapper_full_width {
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  .selector_container {
    width: 100%;
    padding: 7px 25px 7px 10px;

  }

  .dropdown__menu {
    max-width: 100%;
  }
}

.color_selector_wrapper_close {
  .dropdown__menu {
    transform: scaleY(0);
  }
}