@import "../../../styles/index";

.header_action_container {
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	background-color: transparent;
	height: 65px;
	width: 100%;
	z-index: 2;
}

.calendar {
	height: 130px;
	align-items: normal;
}
