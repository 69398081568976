@import './variables.scss';

.confirm_button {
    padding: 13px 40px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background: $gradientBlue;
    color: white;
    margin-left: 5px;
  }

  .delete_button {
    padding: 13px 40px 13px 30px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: $red;
    background: $lightRed;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }