@import '../../../styles/index';

.input_label_container {
  display: flex;
  flex-direction: column;
  width: 100%;



  .input_wrapper {
    display: flex;
    height: 100%;

    .input_icon {
      max-height: 100%;
      aspect-ratio: 1 / 1;
      margin-left: 8px;
      background: $gradientLightBlue;
      border-radius: 10px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 41px;

      svg {
        height: auto;
        width: auto;
      }
    }

    .field__container {
      position: relative;
      width: 100%;

      .close_icon {
        width: 12px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 10px;
        cursor: pointer;
      }

      .hide {
        display: none;
      }

      .field__value {
        width: 100%;
        height: fit-content;
        display: inline;
        border: none;
        border-radius: 10px;
        background-color: $mediumGray;
        //padding: 17px 55px 17px 10px;
        padding: 12px 15px;
        font-size: 14px;
        line-height: 17px;
      }



      input:focus {
        //outline: 1px solid $blueHover;
        outline: none;
      }

      input:required {
        outline: 1px solid $red;
      }

      .error_message {
        position: absolute;
        top: 100%;
        left: 0;
        color: $red;
        padding-left: 5px;
        padding-top: 5px;
      }
    }
  }

  .input_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
    padding-left: 10px;
  }


  &:has(input:disabled) {
    opacity: 0.4;

    .input_title {
      opacity: 0.4;
    }
  }
}