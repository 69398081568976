@import '../../../styles/index';

.collapsible_card {
  border-radius: 10px;
  background: $white;
  width: 100%;
  padding: 28px 0 0 0;

  .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 28px 30px;

    .title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }

    .arrow {
      border: solid $black;
      height: 4px;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(-135deg);
      cursor: pointer;
    }
  }

  .card_content {
    height: fit-content;
    max-height: 100%;
    //overflow-x: auto;
    transition: transform 0.3s ease-out, max-height 0.3s ease-out;
    transform: scaleY(1);
    transform-origin: top;
  }
}

.collapsible_card_close {
  padding-bottom: 0;

  .card_header {
    .arrow {
      border: solid $black;
      height: 4px;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      cursor: pointer;
    }
  }

  .card_content {
    transform: scaleY(0);
    max-height: 0;
  }
}