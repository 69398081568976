@import '../../../../styles/index.scss';

.nav__item {
  display: flex;

  .vertical_marker {
    width: 5px;
    background: transparent;
  }

  .item__inner {
    padding: 10px 15px;
    border-radius: 0px 5px 5px 0px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;

    span {
      font-weight: 600;
      margin-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    svg {
      height: 30px;
      width: 30px;
      fill: $black;
    }
  }
}

.nav-item__active {
  .vertical_marker {
    background: $gradientBlue;
  }

  .item__inner {
    background: $gradientBlueOpacity;

    span {
      background: $gradientBlue;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }

    svg {
      fill: $blueHover;
    }
  }
}
