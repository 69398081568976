@import '../../../styles/index.scss';

.section_back_wrapper {
  display: flex;

  .arrow_button {
    background: #1f1f1f0A;
    border-radius: 10px;
    width: 43px;
    min-width: 43px;
    height: 43px;
    padding: 0;
    margin-right: 15px;


    .arrow_left {
      border: solid $black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      cursor: pointer;

    }

    &:hover {
      background: #0000001A;
    }

  }

  .title_section {
    display: flex;
    flex-direction: column;
    max-width: 420px;

    .title {
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
    }

    .description {
      font-weight: 400;
      font-size: 17px;
      line-height: 20px;
      color: $middleGray;
    }
  }
}