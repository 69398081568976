@import '../../../../styles/index.scss';

.payments_list_container {
  padding: 40px 60px 80px;

  .filters_container {
    display: flex;
    justify-content: space-between;
    padding: 50px 0px 20px 0px;

    .button_wrapper {
      margin: auto 0;
    }
  }
}

.payments_list_container_mobile {
  padding: 30px 10px;

  .filters_container {
    flex-direction: column;

    .button_wrapper {
      padding-bottom: 20px;
      transform: translate(-15px, 0);
    }
  }
}