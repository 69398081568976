@import 'styles/index.scss';

.manager_container {
    padding: 40px 60px 80px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .add_button {
            cursor: pointer;
        }
    }

    .body {
        margin-top: 20dvh;
        display: flex;
        flex-direction: column;
        justify-content: center;

        p {
            text-align: center;
            font-size: 14px;
            line-height: 17px;
            color: $textGray;
        }
    }

    .accounts_list_container {
        display: inline-block;
        margin-top: 40px;
        padding-bottom: 40px;
        height: auto;
        min-height: fit-content;
        max-height: fit-content;
        box-sizing: border-box;
        width: 100%;
    }
}

.manager_container_mbl {
    padding-left: 15px;
    padding-right: 15px;

    .header {
        .add_button {
            position: absolute;
            z-index: 10;
            top: 80%;
            right: 50px;
        }
    }
}