@import '../../../../../../styles/index.scss';

.service_workers_modal_container {
  padding: 40px 60px 80px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .worker_add_button {
      cursor: pointer;
    }

  }

  .name_cell_container {
    display: flex;
    align-items: center;
    margin-top: 45px;
    margin-bottom: 35px;
    margin-left: 30px;

    .client_info {
      margin-left: 20px;

      .name {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        margin-top: 5px;
      }

      .description {
        font-weight: 400;
        font-size: 14px;
        color: $middleGray;
      }
    }
  }

  .button_selector_container {
    display: flex;
    align-items: center;
    margin-top: 35px;

    .button_selector_title {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      margin-right: 20px;
    }
  }

  //** Modal ** .list_container {
  //   background: $white;
  //   border-radius: 15px;
  //   overflow: hidden;

  //   .selector_header {
  //     font-weight: 500;
  //     font-size: 20px;
  //     line-height: 25px;
  //     padding: 25px;
  //   }

  //   .actions_container {
  //     display: flex;
  //     justify-content: flex-end;
  //     margin: 20px;

  //     .confirm_button {
  //       padding: 13px 40px;
  //       border-radius: 10px;
  //       font-weight: 700;
  //       font-size: 14px;
  //       line-height: 17px;
  //       background: $gradientBlue;
  //       color: white;
  //       margin-left: 5px;
  //     }
  //   }
  // }

  .services_list_container {
    margin-top: 40px;
    padding-bottom: 40px;
  }
}

.service_workers_modal_container_mobile {
  padding: 30px 18px 30px;

  .header {
    .worker_add_button {
      position: absolute;
      z-index: 10;
      top: 80%;
      right: 50px;
    }
  }

  .button_selector_container {
    flex-direction: column;
    align-items: normal;

    .button_selector_title {
      margin-bottom: 10px;
    }

    .filters_container {
      flex-wrap: nowrap;
      white-space: nowrap;
      width: calc(98dvw - 5px);
      transform: translate(-15px, 0);
      overflow-x: scroll;
      z-index: 20;

      &::-webkit-scrollbar {
        display: none;
      }

      .button_wrapper {
        padding-bottom: 20px;
      }
    }
  }

}