@import '../../../../styles/index.scss';

.settings_list_container {
  padding: 40px 60px 70px 60px;
  display: flex;
  flex-direction: column;

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  .description {
    max-width: 420px;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    color: $middleGray;
    margin-bottom: 40px;
  }

  .list_container {
    .list_item_container {
      width: 100%;
      display: flex;
      height: fit-content;
      min-height: 130px;
      padding: 20px;
      background: $white;
      border-radius: 14px;
      margin-bottom: 25px;
      justify-content: space-between;
      cursor: pointer;

      &_icon {
        margin: auto 30px auto 0px;
      }

      .info_block {
        display: flex;

        svg {
          width: 22px;
          min-width: 22px;
          height: 22px;
          fill: $black;
        }

        .description_block {
          margin-left: 20px;

          .item_title {
            font-weight: 700;
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 10px;
          }

          .item_description {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $middleGray;
            margin-bottom: 40px;
          }
        }
      }

      .arrow_right {
        border: solid $black;
        height: 3px;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(-45deg);
        align-self: center;
        justify-self: flex-end;
      }

      &:hover {
        background: #F4F4F4;
      }
    }
  }

  .footer__version {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $middleGray;
  }
}

.settings_list_container_mobile {
  padding: 30px 15px;

  .title {
    padding-left: 5px;
  }

  .description {
    padding-left: 5px;
  }

  .list_container {


    .item_description {
      margin-right: 20px;
    }

    .list_item_container {
      .info_block {
        .description_block {
          margin-left: 10px;
        }
      }

      &_icon {
        margin: auto 10px auto 0px;
      }
    }
  }
}