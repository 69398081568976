@import '../../../../../styles/index.scss';

* {
    font-family: 'Poppins';
    font-weight: 300;

    li {
        font-size: 15px;
    }
}

.page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;

    .header {
        position: absolute;
        top: 5%;
        left: 5%;
    }

    .scroll_tab::-webkit-scrollbar {
        display: none; // Safari
    }

    .scroll_tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
        scroll-behavior: smooth;
        scrollbar-width: none;
        overflow-y: hidden;
        height: fit-content;
        width: fit-content;
        margin-bottom: 50px;

        .container {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            min-width: 100%;
            height: max-content;
            margin-bottom: 50px;

            .img_background {
                margin-left: 30px;
                width: 30%;
                height: auto;
            }

            .text_section {
                display: flex;
                flex-direction: column;
                gap: 10px;
                width: 30%;
                margin: 10px 0px 50px;
                margin-left: 7%;

                .h1 {
                    font-weight: 800;
                    font-size: 48px;
                    font-family: 'Antonio';
                    line-height: 1.5;

                }

                .p {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 1.5;
                }

            }


        }
    }

    .buttons {
        position: absolute;
        top: 75%;
        left: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .root {
            background: transparent;
        }

        .dot {
            background-color: $gradientLightBlue;
            width: 13px;
            height: 13px;
            margin: 0px 7px
        }

        .dotActive {
            background-color: $gradientBlue;
        }


        .confirm_button {
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: white;
            margin-left: 5px;
            padding: 13px 30px;
            border-radius: 10px;
        }

        .secondary_button {
            margin-left: 5px;
            padding: 13px 30px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: black;
            border-radius: 10px;
            background-color: transparent;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .container_mobile {
        position: relative;

        .img_background {
            display: none;
        }

        .text_section {
            margin: 20px 10px 0px;
            width: 90% !important;


            .h1 {
                font-size: 43px;
            }
        }

        .steps_wrapper {
            .point_step {
                .text_special {
                    font-size: 45px;
                }
            }
        }

    }

    .buttons {
        left: initial;

        .secondary_button {
            text-decoration: underline;
        }
    }
}