@import '../../../styles/index';

.modal__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25px 34px;

  .header {
    display: flex;
    width: 100%;
    align-items: center;
    height: fit-content;
    padding: 20px 0px;
    gap: 14px;

    .close__icon {
      margin-left: auto;
      cursor: pointer;
      height: 20px;
      width: 20px;

    }

    svg {
      height: 36px;
      width: 36px;
    }

    .title {
      color: $black;
      font-size: 20px;
      font-weight: $font-weight-small;
      line-height: 25px;
    }

  }

  .subtitle {
    font-size: 17px;
    font-weight: $font-weight-small;
    line-height: 20px;
  }



  h3 {
    text-align: center;
    line-height: 1.2;
  }

  .modal__actions {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding-top: 25px;

    .confirm_button {
      width: 48%;
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $red;
      color: $white;
      margin-left: 5px;
    }

    .primary {
      width: 100%;
      padding: 25px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $red;
      color: $white;
      margin-bottom: 25px;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .secondary {
      background: $lightRed ;
      color: $red;
    }

    .return_button {
      width: 48%;
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $black;
    }
  }
}