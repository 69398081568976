@import '../../../../styles/index.scss';

.table_card {
  background: $white;
  border-radius: 10px;

  .header_container {
    padding: 40px 0 40px 63px;
    display: flex;

    .header_cell {
      display: flex;
      margin-right: 50px;

      .name {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;
      }

      .value {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.payment {
  display: flex;
  flex-wrap: nowrap;

  &_info {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    justify-content: space-between;

    .payment_date,
    .payment_services {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.014px;
      color: $middleGray
    }

    .payment_value {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.014px;
      color: $middleGray
    }

    .button_allow {
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $gradientBlue;
      color: white;
      margin-left: 5px;
    }

    .button_not_allow {
      background: transparent;
      color: $red;
    }
  }
}

.payment_mobile_card {
  padding: 0 10px 0 0px;
  display: flex;
  justify-content: space-between;
  gap: 25px;
  height: fit-content;
  font-family: 'Poppins' !important;

  .payment_info {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .section_info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      gap: 5px;
      min-height: 100%;
      max-width: 60%;

      .head {
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        .date {
          display: flex;
          gap: '1px';

          .calendar {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
          }
        }

      }
    }

    .section_actions {
      display: flex;
      flex-direction: row;
      gap: 15px;
      min-height: 100%;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 5px;
      }
    }

    .payment_name {
      font-size: 20px;
      font-weight: 500;
      line-height: 20px;
      color: $black;
    }

    .payment_date {
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 19px;
      letter-spacing: 0.014px;
      color: $black;
      vertical-align: bottom;
    }

    .payment_services,
    .payment_refund {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0.014px;
      color: $black;

    }

    .payment_refund {
      color: $gradientBlue;
      text-decoration: underline;
      text-align: center;
    }

    .payment_value {
      min-width: 60px;
      padding: 2px 10px;
      justify-content: center;
      align-items: center;

      border-radius: 5px;
      font-weight: 600;
      font-size: 17px;
      line-height: 20px;
      color: $black;
      text-align: bottom;
      display: flex;
    }

    .detail_arrow {
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {

        path {
          fill: black;
        }
      }
    }

  }

  .avatar_container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
  }

  .card_info_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;

    .card_avatar_social {
      display: flex;
      flex-direction: column;
      padding-top: 15px;

      .card_avatar_name {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
      }

      .card_social {
        display: flex;
        padding-top: 15px;
      }
    }
  }
}

.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  margin: auto 0;
}

.name_cell_container {
  display: flex;
  align-items: center;

  .payment_info {
    margin-left: 20px;

    .name {
      overflow: hidden;
      color: $black;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 17px;
      letter-spacing: 0.014px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      color: $middleGray;
    }
  }
}