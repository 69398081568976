@import '../../../../../../styles/index.scss';

.delete_cell_container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-right: 60px;

  .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;

    background: $gradientRed;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  svg {
    width: 18px;
    height: 18px;
    margin-left: 10px;
  }
}

.day_value {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $middleGray;

  &:first-letter {
    text-transform: capitalize;
  }
}

.empty_card {
  padding: 60px 23px 0px 56px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $middleGray;
}

.action_footer {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  border-bottom: 1px solid $borderColor;
  padding-top: 5px;
  padding-bottom: 20px;

  .action_button {
    width: fit-content;
    display: flex;
    align-items: center;
    color: $gradientBlue;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-right: 60px;
    cursor: pointer;

    svg {
      width: 19px;
      height: 19px;
      margin-left: 9px;
    }
  }
}