@import 'styles/index.scss';

$modal-height: 80dvh;
$mobile-modal-height: 100dvh;

.service_modal_wrapper {
    width: 100%;
    max-height: $modal-height;
    overflow: auto;

    .body {
        padding: 20px 30px 40px;
        max-height: calc($modal-height - 200px);
        overflow-y: auto;
        scroll-behavior: smooth;
        height: fit-content;

        .alert {
            display: flex;
            align-items: flex-start;
            gap: 5px;
            color: $red;
            background: rgb($red, 0.2);
            border: 1px solid $red;
            padding: 8px 12px;
            border-radius: 4px;
            margin-bottom: 10px;
            line-height: 20px;

            svg {
                width: 20px;
                height: 20px;

                path {
                    fill: $red;
                }
            }
        }
    }

    .footer {
        padding: 15px 50px;
    }

    .actions_container {
        display: flex;
        justify-content: flex-end;

        .confirm_button {
            width: 100%;
            padding: 18px 40px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: white;
        }

        .button_disabled {
            background: $lightgray2;
            color: $textGray;
            cursor: not-allowed;
        }
    }
}

.service_modal_wrapper_mobile {
    height: 60dvh;
    max-height: $mobile-modal-height;
    display: flex;
    flex-direction: column;


    .body {
        max-height: calc($mobile-modal-height - 200px);
    }

    .footer {
        margin-top: auto;
        padding: 15px 30px;
    }

}