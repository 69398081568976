@import '../../../../styles/index';

.queue_container {
  margin-bottom: 43px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $borderColor;
  border-bottom: 1px solid $borderColor;
  max-height: 90dvh;
  overflow: auto;

  .worker_wrapper {
    display: flex;
    height: fit-content;
    border-bottom: 1px solid $borderColor;

    .worker_container {
      width: 40%;
      border-right: 1px solid $borderColor;
      padding: 20px 0;
      display: flex;
      font-size: 12px;
      justify-content: center;
      background-color: $white;

      .worker_info_container {
        display: flex;
        align-items: center;
        height: fit-content;

        .option_value {
          font-weight: 500;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: auto;

        }

        .value_icon {
          padding-right: 10px;
        }

        .menu_icon {
          font-size: 15px;
          cursor: pointer;
        }
      }
    }

    .clients_wrapper {
      width: 60%;

      .client_container {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $borderColor;
        padding: 20px 0 20px 40px;
        align-items: center;

        .client_name_container {
          display: flex;
          align-items: center;
          width: 100%;

          .option_value {
            font-weight: 500;
            font-size: 14px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
          }

          .value_icon {
            padding-right: 10px;
          }
        }

        .client_social_container {
          display: flex;
          flex-wrap: nowrap;
          padding-right: 20px;
          width: auto;
        }

        .client_actions_container {
          display: none;
          padding-right: 20px;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .hide {
    display: none;
  }
}

.queue_container_mobile {
  .worker_wrapper {
    .worker_container {
      width: 40%;
      padding: 10px;
    }

    .clients_wrapper {
      width: 60%;

      .client_container {
        padding: 10px;

        .client_social_container {
          display: none;

        }

        .client_actions_container {
          display: block;
        }
      }
    }
  }
}