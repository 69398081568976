@import '../../../../../../styles/index.scss';

.extra_time_workers_container {
  padding: 40px 60px 80px;

  .extra_time_add_button {
    display: none;
    position: absolute;
    z-index: 10;
    top: 80%;
    right: 50px;
    cursor: pointer;
  }

  .tables_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 70px 2% 20px 2%;

    .picker_container {
      min-width: 150px;
    }

    .name_cell_container {
      display: flex;
      align-items: center;

      .client_info {
        margin-left: 20px;

        .name {
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          margin-bottom: 5px;
        }

        .description {
          font-weight: 400;
          font-size: 14px;
          color: $middleGray;
        }
      }
    }
  }

}

.extra_time_workers_container_mobile {
  padding: 30px 18px 30px;

  .extra_time_add_button {
    display: block;
  }

  .tables_actions {
    width: 100%;
    flex-wrap: wrap;

    .picker_container {
      width: 100%;
      padding-top: 30px;
    }

    .name_cell_container {
      width: 100%;
    }
  }
}

.extra_time_cards_list_container {

  .empty_card {
    padding: 20px 26px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $middleGray;
  }

  .extra_time_card {
    padding: 30px 20px;
    border-bottom: 1px solid $borderColor;

    &:last-child {
      border-bottom: none;
    }

    .card_row_payment,
    .card_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 17px;

      .row_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }

      .row_value {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $middleGray;
      }
    }

    .card_row_payment {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;
      padding-bottom: 17px;

      .badge_payment,
      .badge_payment_active {
        width: 100%;
        padding: 13px 20px;
        border-radius: 5px;
        background-color: #F4F4F4;
        color: #1f1f1f;
        text-align: center;

        &_active {
          background-color: #65CE8F;
          color: #fff;
        }
      }
    }

    .delete_button {
      padding: 13px 40px;
      margin-top: 20px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $red;
      background: $lightRed;
      width: 100%;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}