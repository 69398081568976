@import 'src/styles/index.scss';



.cards {
        display: flex;
        width: 100%;
        justify-content: space-between;

        h2 {
                color: $middleGray;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 25px;
        }

        &_card {
                display: flex;
                gap: 20px;
                align-items: center;
        }


        .cards_button {
                width: 304px;
                max-width: 100%;
                background: $gradientLightBlue;
                color: $gradientBlue;
                border-radius: 10px;
                font-weight: 700;
                padding: 13px 33px;
                justify-content: flex-start;
                align-items: center;

                .icon {
                        margin-right: 5px;
                        min-width: 17px;
                        min-height: 17px;
                        max-width: 17px;
                        max-height: 17px;
                }

                &:hover {
                        path {
                                fill: $gradientLightBlue;
                        }

                        color: $gradientLightBlue;
                        background: $gradientBlue;
                        filter: brightness(0.8);
                }
        }
}

.cards_mobile{
        display: flex;
        width: 100%;
        flex-direction: column;

        h2 {
                color: $middleGray;
                font-size: 21px;
                font-style: normal;
                font-weight: 300;
                line-height: 25px;
        }

        .cards_card {
                display: flex;
                gap: 30px;
                justify-content: flex-start;
                align-items: center;
        }


        .cards_button {
                width: 100%;
                max-width: 100%;
                background: $gradientLightBlue;
                color: $gradientBlue;
                border-radius: 10px;
                font-weight: 700;
                padding: 13px 33px;
                margin-top: 13px;
                align-items: center;
                justify-content: center;

                .icon {
                        margin-right: 5px;
                        min-width: 17px;
                        min-height: 17px;
                        max-width: 17px;
                        max-height: 17px;
                }

                &:hover {
                        path {
                                fill: $gradientLightBlue;
                        }

                        color: $gradientLightBlue;
                        background: $gradientBlue;
                        filter: brightness(0.8);
                }
        }


}