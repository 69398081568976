@import '../../../../../styles/index.scss';

* {
    font-family: 'Poppins';
    font-weight: 300;

    li {
        font-size: 15px;
    }
}

.page {
    display: flex;
    justify-content: center;

    height: 100%;
    width: 100%;
    position: relative;

    .header {
        position: absolute;
        top: 5%;
        left: 5%;
    }

    .scroll_tab::-webkit-scrollbar {
        display: none; // Safari
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;
        width: fit-content;
        min-width: 50%;
        height: fit-content;
        margin-top: 10%;
        margin-bottom: 50px;
        gap: 20px;

        .client_modal_wrapper {
            width: 100%;
            height: fit-content;




            .body {
                overflow-y: auto;
                scroll-behavior: smooth;
                height: fit-content;
                background-color: $white;
                border-radius: 14px;
                border: 1px solid #7878784d;
                padding: 20px 30px;

                .p {
                    font-weight: 300;
                    font-size: 15px;
                    line-height: 1.5;
                }

                .error_text {
                    display: flex;
                    color: $red;
                    font-size: 12px;
                    line-height: 1.2;
                    padding-left: 10px;
                    margin-bottom: 10px;
                }
            }

            .footer {
                padding: 30px 50px;
            }

            .actions_container {
                display: flex;
                justify-content: center;
                gap: 20px;

                .root {
                    background: transparent;
                }

                .dot {
                    background-color: $gradientLightBlue;
                    width: 13px;
                    height: 13px;
                    margin: 0px 7px
                }

                .dotActive {
                    background-color: $gradientBlue;
                }


                .confirm_button {
                    border-radius: 10px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    background: $gradientBlue;
                    color: white;
                    margin-left: 5px;
                    padding: 13px 30px;
                    border-radius: 10px;
                    background-color: $gradientBlue;
                }

                .secondary_button {
                    margin-left: 5px;
                    padding: 13px 30px;
                    background-color: black;
                }
            }


        }

        .text_section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin: 10px 0px 30px;

            .title {
                font-weight: 800;
                font-size: 48px;
                font-family: 'Antonio';
                line-height: 1.5;

            }

            .p {
                font-weight: 300;
                font-size: 15px;
                line-height: 1.5;
            }

        }


    }

}

.page {
    .container_mobile {
        margin: 0 20px 50px;
        margin-top: 20%;
        gap: 20px;

        .img_background {
            display: none;
        }

        .client_modal_wrapper_mobile {

            .footer {
                padding: 15px 30px;
            }

        }

        .text_section {
            margin: 20px 10px 0px;
            width: fit-content;

            .h1 {
                font-size: 43px;
            }
        }
    }
}