@import '../../../../../styles/index';


.container {

    max-height: 80dvh;
    display: flex;
    flex-direction: column;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-height: 570px;
    border-radius: 25px;
    background-color: $white;
    font-family: "Poppins";



    .body {
        height: fit-content;
        width: fit-content;
        padding: 30px 25px 5px; //5px 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        //overflow-y: scroll;

        .text {
            font-size: 14px;
            font-weight: 500;
        }


        .card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $black;
            border-radius: 10px;
            height: 475px;
            width: 270px;
            padding: 30px;
            gap: 20px;


            h1,
            h2,
            h3 {
                color: $white;
                font-weight: 400;
                font-family: "Poppins";
            }

            h1 {
                font-size: 18px;
                font-weight: 500;
            }

            h2 {
                font-size: 14px;
            }

            h3 {
                font-size: 12px;
            }

            .head {
                display: flex;
                flex-direction: row;
                gap: 15px;
                align-items: center;
                width: 100%;
            }

            .head_wider {
                flex-direction: column;
            }

            .big_text {
                width: 100%;
                font-size: 40px;
                color: $gradientBlue;
                font-family: "Antonio";
                letter-spacing: 0.0px;
                text-transform: uppercase;
                font-weight: 700;
                line-height: 1;
            }

            .heavy_text {
                font-size: 65px;
                color: $gradientBlue;
                font-family: "Antonio";
                letter-spacing: 0.0px;
                text-transform: uppercase;
                font-weight: 700;

            }

            .gold {
                color: $gold;
            }

            .time_card {
                width: 48%;
                background-color: $white;
                padding: 13px 11px;
                border-radius: 10px;
                min-width: min-content;

                .text {

                    color: #787878;
                    white-space: nowrap;
                    font-size: 13px;
                    font-family: "Roboto";
                    text-align: center;

                }
            }

            .foot {
                color: $white;
                display: flex;
                justify-content: center;
                width: 100%;
                align-items: center;
                gap: 10px;
                margin: auto 0 0 0;

            }


        }

    }

    .footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 15px;
        width: 100%;
        padding: 15px 20px;
        //border-top: 1px solid #545454;

        .action_btn {
            background: $gradientBlue;
            border-radius: 10px;
            padding: 17px 30px; //20px 40px;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $white;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            align-items: center;
            gap: 5px;

            .icon {
                path {
                    fill: white
                }
            }

            &:hover {
                -webkit-filter: brightness(0.8);
                filter: brightness(0.8)
            }



        }

        /*         .disable {
            cursor: block;
            background: $lightgray;

            &:hover {
                filter: none;
            }
        }
 */
        .link_btn {
            padding: 17px 20px;
            overflow: hidden;
            width: fit-content;
            cursor: pointer;
            font-size: 14px;
            font-weight: 500;
            color: $middleGray;
            text-decoration: underline;


            &:hover {

                color: $middleDarkGray !important;
                font-weight: 700;
                text-decoration: underline;

            }

            &_disabled {
                cursor: block;

                .ltext {
                    font-size: 14px;
                    font-weight: 600;
                    color: $textGray;
                }

            }

        }

    }


    .linear_progress_box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 50px;

        &_text {
            text-align: start;
            font-size: 14px;
        }
    }
}

.container_mbl {
    height: 100%;
    max-height: none;
    align-items: center;

    .body {
        height: 100%;
        max-height: none;
        padding: 30px 25px;

        .card {
            scale: 1.2;
        }

    }


    .footer {
        margin: auto 0 0 0;
    }
}

.container_xmas {
    .body {

        .card {
            background-color: #FAF1E3;

            h1,
            h2,
            h3 {
                color: #1D5E62;
            }

            .big_text {
                color: #DA4545;
            }

            .heavy_text {
                color: #DA4545;
            }

            .gold {
                color: #1D5E62;
            }

            .time_card {
                box-shadow: rgba(50, 50, 93, 0.2) 0px 2px 5px -1px, rgba(0, 0, 0, 0.11) 0px 1px 3px -1px;

            }

        }

        .foot {
            color: #1D5E62;
        }
    }
}