@import '../../../../styles/index';

.modal__content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 25px 34px;

  .title {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    padding-top: 20px;
    text-align: center;
  }
}
