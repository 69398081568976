@import '../../../../../../styles/index';

.header_modal_container {
  display: flex;
  flex-direction: column;
  padding: 25px;
  max-height: 90dvh;
  overflow: auto;

  .text {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 20px;
  }

  .selector_container {
    margin-bottom: 20px;
  }

  .time {
    display: flex;
    gap: 20px;
    margin-top: 10px;
  }

  .confirm_button {
    width: 100%;
    padding: 18px 40px;
    margin-top: 120px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    background: $gradientBlue;
    color: white;
  }
}