@import '../../../../styles/index';

.scheduler__day__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  .day__header {

    min-height: 50px;
    height: 50px;
    position: sticky;
    background-color: $white;
    z-index: 21;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20px;
    border-bottom: 1px solid $borderColor;
    border-right: 2px solid $middleGray;
    font-weight: 400;
    font-size: 20px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .day_time_line_wrapper {

    .day_time_line {
      width: 100%;
      position: absolute;
      left: 0;
      border-bottom: 1px solid $borderColor;
      z-index: 17;

      &:first-child {
        border-bottom: none;
      }
    }
  }

  .day_blocked {

    position: absolute;
    top: 100px;
    height: calc(100% - 100px);
    left: 0;
    width: 100%;
    background-color: #78787826;
  }

  .workers_container {
    display: flex;

    width: 100%;
    height: 100%;
  }
}