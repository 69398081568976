@import '../../../../styles/index.scss';

.clients_list_container {
  padding: 40px 60px 80px;

  .header {
    display: flex;
    justify-content: space-between;
  }

  .filters_container {
    display: flex;
    justify-content: space-between;
    padding: 50px 10px 20px 10px;

    .button_wrapper {
      margin: auto 0;
    }
  }


}

.plus_client {
  cursor: pointer;
}

.plus_client_mbl {
  cursor: pointer;
  position: absolute;
  bottom: 15%;
  right: 8%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;


  .inviteButton {
    display: inline-block;
    justify-content: center;
    align-items: center;
    padding: 13px;
    border-radius: 50%;
    background-color: #DFE0FF;
    box-shadow: rgba(160, 161, 194, 0.4) 0px 2px 4px, rgba(160, 161, 194, 0.2) 0px -3px 0px inset;

    path {
      stroke: $black;
      fill: none;
    }
  }
}

.clients_list_container_mobile {
  padding: 30px 15px;

  .header {
    justify-content: flex-start;
  }

  .filters_container {
    flex-wrap: wrap;

    .button_wrapper {
      padding-bottom: 20px;
      width: calc(98dvw - 5px);
      transform: translate(-15px, 0);
      overflow-x: scroll;

    }
  }
}