@import '../../../../../../styles/index.scss';

.service_workers_modal_container {
  padding: 40px 60px 80px;

  .name_cell_container {
    display: flex;
    align-items: center;
    margin-top: 45px;
    margin-bottom: 35px;
    margin-left: 30px;

    .client_info {
      margin-left: 20px;

      .name {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
        margin-bottom: 5px;
      }

      .description {
        font-weight: 400;
        font-size: 14px;
        color: $middleGray;
      }
    }
  }

  .list_container {
    background: $white;
    border-radius: 15px;
    overflow: hidden;

    .selector_header {
      font-weight: 500;
      font-size: 20px;
      line-height: 25px;
      padding: 25px;
    }

    .actions_container {
      display: flex;
      justify-content: flex-end;
      margin: 20px;

      .confirm_button {
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        color: white;
        margin-left: 5px;
      }
    }
  }
}

.service_workers_modal_container_mobile {
  padding: 30px 18px 30px;

  .list_container {
    .actions_container {
      .confirm_button {
        width: 100%;
        height: 50px;
      }
    }
  }
}
