$black: #1F1F1F;
$darkBlack: #090A0B;
$darkBlue: #0040DD;
$textGray: #AEAEB2;
$lightgray: #B1B1B1;
$middleDarkGray: #606060;
$middleGray: #787878;
$lightgray2: #F4F4F4;
$green: #F6FFD2;
$greenDark: #448945;
$greenlight: #BAE804;
$bluesky: #d2efff;
$blueskyligth: #35a8f6;
$redligth: #ffe3e9;
$red: #FF6A88;
$lightRed: #FFF7F7;
$blueHover: #8549FE;
$blue: #409CFF;
$white: #FFFFFF;
$clearGray: #F6F7F8;
$trackGray: #E0E0E0;
$mediumGray: #F5F5F5;
$gradientBlueOpacity: #DFE0FF;
$gradientBlue: #858AF9;
$gradientLightBlue: #DFE0FF;
$gradientGreen: linear-gradient(180deg, #05DCA9 0%, #02AD84 100%);
$gradientRed: linear-gradient(180deg, #FF6C70 0%, #FF6895 100%);
$borderColor: rgba(120, 120, 120, .3);
$lightYellow: #FFF2E6;
$gold: #FCB734;

:export {
    gradientBlueOpacity: $gradientBlueOpacity;
    gradientBlue: $gradientBlue;
    gradientLightBlue: $gradientLightBlue;
    gradientGreen: $gradientGreen;
    gradientRed: $gradientRed;
    lightYellow: $lightYellow;
    gold: $gold;
    black: $black;
    darkBlack: $darkBlack;
    darkBlue: $darkBlue;
    textGray: $textGray;
}