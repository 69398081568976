@import '../../../../styles/index';

.worker__wrapper {
  width: 100%;
  height: calc(100% - 50px);

  .worker_header {
    border-right: 1px solid $borderColor;
    border-bottom: 1px solid $borderColor;
    height: 50px;
    display: flex;
    font-size: 12px;
    justify-content: center;
    padding: 0 10px;
    align-items: center;
    text-align: center;
    position: -webkit-sticky;
    position: sticky;
    z-index: 20;
    -webkit-user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;


    &:hover {
      background: #e5e5e5 !important;
    }

    .option_value {
      font-weight: 500;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .option_badge {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: auto;
      font-size: 13px !important;

      border: 1px solid $gradientBlue;

      border-radius: 15px;
      padding: 2px 7px;
      color: $gradientBlue
    }

    .value_icon {
      padding-right: 10px;
    }

    .menu_icon {
      //padding: 5px 0px 0px;
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      margin-left: auto;

      font-size: 15px;
      cursor: pointer;

      //transform: rotate(0deg);
      transition: 0.4s;
    }

    .menu_icon_close {
      transform: rotate(-180deg);
      transition: 0.4s;
    }
  }


  .worker_container {
    height: 100%;
    position: relative;
    z-index: 18;
    border-right: 1px solid $borderColor;
  }

  &:last-child {
    .worker_header {
      border-right: 2px solid $middleGray;
    }

    .worker_container {
      border-right: 2px solid $middleGray;
    }
  }

  .worker_selector {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;

    .settings_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      padding-right: 8px;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    svg {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }

    .dropdown__menu {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      position: absolute;
      top: 100%;
      right: 0;
      overflow: hidden;
      transition: transform 0.4s ease-out;
      transform-origin: top;
      margin-top: 5px;

      .text {
        font-size: 14px;
        font-weight: 500;
      }

      .dropdown__menu_inner_list {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;

        width: 100%;
        background: $white;
        display: flex;
        flex-direction: column;
        padding: 30px 15px 15px 15px;
        border-radius: 10px;
        gap: 15px;

        .close_btn {
          padding: 9px 0px 9px 9px;

          .icon {
            width: 13px;
            height: 13px;
          }
        }

        .dropdown__menu_btn {
          /*width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;*/
          background: $gradientLightBlue;
          border-radius: 10px;
          padding: 17px 38px;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: $gradientBlue;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &:hover {
            background: $gradientBlue;
            color: $white;
          }
        }
      }

      /* not working
      .header {
        display: 'flex';
        flex-direction: 'row';
        gap: '10px';
        justify-content: 'space-between';

        .user {
          display: 'flex';
          flex-direction: 'row';
          gap: '5px';
          align-items: 'center';
        }
      } */

      .link {
        .icon {
          width: 15px;
          height: 15px;

          path {
            fill: $gradientBlue;
          }
        }

        .ltext {
          font-size: 14px;
          font-weight: 600;
          color: $gradientBlue ;
          text-align: left;
        }

        &:hover {
          .ltext {
            color: #797fe8 !important;
            font-weight: 700;
            text-decoration: underline;
          }
        }
      }

    }
  }

  .worker_selector_close {
    .dropdown__menu {
      transform: scaleY(0);
    }


  }
}

.linear_progress_box {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &_text {
    text-align: start;
  }
}

.notice {
  margin-top: 10px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  flex-direction: row;
  width: 100%;
  font-size: 14px;
}