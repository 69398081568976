@import '../../../../styles/index';

.scheduler__wrapper {
  padding: 10px 20px 0px;
  height: 100%;
  width: 100%;

  ::-webkit-scrollbar-track {
    border-top-right-radius: 10px;
  }

  .scheduler__container {
    background: $white;
    border-radius: 10px;
    overflow: auto;
    height: 100%;
    width: 100%;
    display: flex;

    .scheduler__inner {
      position: relative;
      z-index: 1;
      display: flex;
      min-width: fit-content;
      width: 100%;

      .scheduler_time_line_container {
        position: sticky;
        width: 50px;
        border-right: 1px solid $borderColor;
        top: 0;
        left: 0;
        background: $white;
        z-index: 100;

        .time_line_corner {
          position: sticky;
          top: 0;
          left: 0;
          height: 100px;
          width: 100%;
          background-color: white;
          z-index: 2;
          border-bottom: 1px solid $borderColor;
        }

        .time_line_value_container {
          width: 100%;
          display: flex;
          position: absolute;
          left: 0;
          z-index: 1;
          justify-content: center;

          .time_line_value {
            font-weight: 700;
            font-size: 14px;
            color: $black;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }

        .time_line_value_container_half {
          width: 100%;
          display: flex;
          position: absolute;
          left: 0;
          z-index: 1;
          justify-content: center;

          .time_line_value {
            font-weight: 700;
            font-size: 10px;
            color: $lightgray;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }

        .time_line_value_container_subtime {
          width: 100%;
          display: flex;
          position: absolute;
          left: 0;
          z-index: 1;
          justify-content: center;

          .time_line_value {
            font-weight: 700;
            font-size: 14px;
            color: $white;
            -webkit-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
        }
      }

      .scheduler_tasks_container {
        width: 100%;
        display: flex;
      }
    }
  }

  &__mobile {
    padding: 0px;
  }
}

.toast_success {
  background: $green !important;
  color: $white !important;
  border-radius: 12px !important;
}

.toast_success_progress {
  background: $greenlight !important;
  border-radius: 12px !important;
}

.toast_warning {
  background: $redligth !important;
  color: $white !important;
  border-radius: 12px !important;
}

.toast_warning_progress {
  background: $red !important;
  border-radius: 12px !important;
}