@import '../../../../styles/index.scss';

.container {
    width: 100%;
    height: fit-content;
    box-shadow: 0px 5px 10px -10px #6C7982;
    border-radius: 14px;
    background-color: $white;
    margin: 20px 0px;
    padding: 25px 50px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .header {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 60%;

        .h1 {
            font-size: 20px;
            font-weight: 700;
            line-height: 25px;
            padding-bottom: 10px;
        }

        .description {
            width: 90%;
            font-weight: 400;
            font-size: 15px;
            line-height: 17px;
            color: $middleGray;

            p {
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                font-family: 'Poppins';
                color: $middleGray;

            }
        }

    }

    .img {
        height: auto;
        width: 30%;

        &_bg {
            width: 100%;
            max-height: 500px;
            border-radius: 15px;
            border-color: #1f1f1f;
        }
    }

    .confirm_button {
        position: absolute;
        bottom: 30px;
        left: 50px;
        margin-top: auto;
        width: fit-content;
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        color: white;
        margin-left: 0;
    }

    &_mbl {
        padding: 13px;
        align-items: center;

        &.container_bg {
            min-height: 400px;
            max-height: 400px;
            padding-bottom: 150px;

            .img_bg {
                width: 100%;
                max-height: 400px;
                border-radius: 15px;
                border-color: #1f1f1f;
            }

            .confirm_button {
                width: 90%;
                left: 20px;
            }
        }

        .header {
            width: 67%;
        }

        .img {
            height: fit-content;
            max-height: 30%;
            width: 50%;
        }

        .confirm_button {
            bottom: 20px;
            right: 30px;
            left: auto;
        }
    }


}

.container_bg {
    padding: 0px;
    background-color: #1f1f1f;
    overflow: hidden;

    .header {
        display: none;
    }



}