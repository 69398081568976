@import '../../../../../../styles/index.scss';

.worker_time_modal_container {
  display: flex;
  flex-direction: column;
  max-height: 90dvh;
  overflow: auto;

  .worker_name {
    padding-left: 30px;
    padding-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .form_container {
    padding: 20px 30px;

    .radio_button {
      height: 20px;
      width: 20px;
    }

    .time_selector_wrapper {
      display: block;

      .time_selector_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 27px;
        padding-top: 50px;
      }

      .time_selector_container {
        display: flex;
        width: 100%;
        flex-wrap: wrap;

        .time_selector_item_container {
          width: 100%;
          display: flex;

          .time_selector_item {
            width: 40%;
            padding-bottom: 30px;

            &:first-child {
              margin-right: 30px;
            }
          }

          .time_selector_item_delete {
            display: flex;
            align-items: flex-end;
            width: calc(40% + 47px);
            svg {
              min-width: 37px;
              min-height: 37px;
              padding: 7px;
              margin-left: 10px;
              border-radius: 10px;
              background: $lightRed;
              cursor: pointer;
            }
          }

          .time_selector_extra_field {
            display: flex;
          }
        }

        .time_selector_item_container_all_fields {
          display: flex;
        }

        .time_selector_item_container_full_day {
          display: none;
        }
      }

      .time_selector_container_full_day {
        .time_selector_item_container_all_fields {
          display: none;
        }

        .time_selector_item_container_full_day {
          display: flex;
        }
      }

      .time_selector_add_new_field {
        display: block;
        font-weight: 700;
        font-size: 11px;
        line-height: 16px;
        color: $gradientBlue;
        cursor: pointer;
        padding-top: 15px;
        opacity: 1;
      }

      .time_selector_add_new_field_hide {
        opacity: .3;
      }
    }

    .time_selector_wrapper_hide {
      display: none;
    }

    .form_actions {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;

      .confirm_button {
        width: 100%;
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        color: white;
        margin-left: 5px;
      }
    }
  }
}
