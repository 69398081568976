@import 'styles/index.scss';

.sections_wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
    margin-bottom: 20px;

    .title {
        font-size: 14px;
        line-height: 17px;
        font-weight: 700;
        padding: 0 10px;
        margin: 0 0 10px;
    }

    .sections {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        align-items: center;

    }

}

.section_accordion {
    width: 100% !important;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    // align-items: center;
    cursor: pointer;

    summary {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 10px;
        cursor: pointer;

        .title_button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px 30px;
            font-size: 14px;
            line-height: 17px;
            font-weight: 700;
            width: 100%;
            border-radius: 8px;
            border: 1px solid $black;

            &:hover {
                background: $lightgray2;
            }

            &_opened {
                background: transparent;
                border: 1px solid $gradientBlue;
                border-radius: 8px 8px 0px 0px;
                color: $gradientBlue;

                &:hover {
                    filter: brightness(0.8);
                }
            }

            &_selected {
                background: $gradientLightBlue;
                border: 1px solid $gradientBlue;
                color: $gradientBlue;

                &:hover {
                    filter: brightness(0.8);
                }
            }

        }

        .selected_btn {
            display: flex;
            align-items: center;
            width: fit-content;
            min-width: fit-content;
            flex-wrap: nowrap;
            gap: 10px;
            min-height: 43px;
            padding: 12px 10px;
            border-radius: 8px;
            background-color: $lightgray2;

            &:hover {
                filter: brightness(0.8);
            }
        }
    }

    .options_box {
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: 100% !important;
        padding: 10px 10px;
        border-radius: 0px 0px 10px 10px;
        border: 1px solid $gradientBlue;
        border-top: none;

        .option_item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-height: 50px;
            cursor: pointer;
            border-bottom: 1px solid $borderColor;
            padding-left: 20px;
            padding-right: 20px;

            &:nth-last-child(1) {
                border-bottom: 1px solid transparent;
            }

            &:hover {
                background: $lightgray2;
            }

            .option_value {
                display: flex;
                align-items: center;
                overflow: auto;
                gap: 5px;
                font-weight: 500;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

        }

        .radio_button {
            height: 20px;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            position: relative;
            accent-color: transparent;
            cursor: pointer;

            &:checked {
                accent-color: transparent;
            }

            &:after {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                content: '';
                visibility: visible;
                background: $white;
                outline: 1px solid #0000007f;
            }

            &:checked:after {
                width: 12px;
                height: 12px;
                border-radius: 15px;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                position: absolute;
                background-color: $gradientBlue;
                content: '';
                display: inline-block;
                visibility: visible;
            }

            &:checked:before {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                position: relative;
                content: '';
                visibility: visible;
                background: $white;
                outline: 1px solid #0000007f;
            }

        }

        .select_button {
            &:checked {
                accent-color: transparent;
            }

            &:after {
                border-radius: 20%;
            }

            &:checked:after {
                border-radius: 20%;
                background-color: $gradientBlue;
            }

            &:checked:before {
                border-radius: 20%;
            }
        }

    }
}