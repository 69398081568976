@import '../../../../styles/index.scss';

* {
  span {
    font-family: 'Poppins';
  }
}

.container__inner {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;
  align-items: center;
  overflow: auto;
  gap: 20px;

  .login__container {
    color: #06262D;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    width: 100%;
    //background: white;

    .logo {
      width: 50%;
      max-width: 180px;
      display: block;
      margin-top: auto;
      margin-bottom: 50px;
    }

    h1 {
      color: $black;
      font-size: 60px;
      font-weight: 700;
      font-style: normal;
      letter-spacing: -0.029em;
      text-align: center;
      line-height: 25px;


      &>span {
        color: $gradientBlue;
        font-size: 60px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: -0.029em;
      }
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      line-height: 25px;
      color: $middleGray;
      text-align: center;
      margin-bottom: 46px;
      max-width: 700px;
      margin-top: 10px;
    }

    .login__form {
      display: flex;
      flex-direction: column;
      width: 100%;
      width: 375px;


      /* &>*:last-child {
        margin-top: 25px;
      } */
    }

    .errorMsg {
      display: flex;
      color: $red;
      font-size: 12px;
      line-height: 1.2;
    }
  }

  .confirm_button {
    padding: 13px 40px;
    border-radius: 10px;
    font-weight: 700;
    font-size: 14px;
    height: 53px;
    line-height: 17px;
    background: $gradientBlue;
    color: white;
    //margin-left: 5px;
  }

  .pwdRecovery {
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-top: 40px;
    color: $gradientBlue;

    span {
      font-size: 14px;
      color: $gradientBlue
    }
  }

  .signUp {
    width: 375px;

    .confirm_button {
      cursor: pointer;
      margin-top: 40px;
      width: 100%;
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      height: 53px;
      line-height: 17px;
      background: $black;
      color: white;
    }

  }

  .footer {
    margin-top: auto;
    text-align: center;
    color: $middleGray;
    line-height: 20px;
    font-size: 12px;
    font-family: 'Poppins';

    span {
      cursor: pointer;
      font-size: 12px;
      text-decoration: none;
      color: $black;
    }
  }

  &__mobile {


    .login__container {
      .logo {
        margin-top: 40px;
      }

      p {
        max-width: 294px;
        margin-top: 20px;
      }

      h1 {
        font-size: 40px;
        line-height: 40px;

        &>span {
          font-size: 40px;
        }
      }
    }
  }
}