@import '../../../../styles/index.scss';
$modal-height: 80dvh;
$mobile-modal-height: 99dvh;

.client_modal_wrapper {
    width: 100%;
    max-height: $modal-height;

    .body {
        padding: 20px 30px;
        max-height: calc($modal-height - 200px);
        overflow-y: scroll;
        scroll-behavior: smooth;
        height: fit-content;

        .error_text {
            display: flex;
            color: $red;
            font-size: 12px;
            line-height: 1.2;
            padding-left: 10px;
            margin-bottom: 10px;
        }
    }

    .footer {
        box-shadow: inset rgba(0, 0, 0, 0.2) 0px 2px 1px;
        padding: 15px 50px;
    }

    .actions_container {
        display: flex;
        justify-content: flex-end;

        .confirm_button {
            width: 100%;
            padding: 18px 40px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: white;
        }
    }
}

.client_modal_wrapper_mobile {
    max-height: $mobile-modal-height;


    .body {
        max-height: calc($mobile-modal-height - 200px);
    }

    .footer {
        padding: 15px 30px;
    }

}