@import '../../../../styles/index.scss';

.table_card {
  background: $white;
  border-radius: 10px;

  .header_container {
    padding: 40px 0 40px 63px;
    display: flex;

    .header_cell {
      display: flex;
      margin-right: 50px;

      .name {
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        margin-right: 10px;
      }

      .value {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.table_card_mobile {
  .header_container {
    padding: 20px 0 20px 20px;
    flex-wrap: wrap;
    border-bottom: 1px solid $borderColor;

    .header_cell {
      padding-bottom: 20px;
    }
  }
}

.client_actions {
  display: flex;
  flex-wrap: nowrap;
}

.client_mobile_card {
  padding: 0 25px 0 0px;
  display: flex;

  .avatar_container {
    width: fit-content;
    height: 100%;
    position: relative;

    svg {
      position: absolute;
      width: 18px;
      height: 18px;
      right: 0;
    }
  }

  .card_info_container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;

    .card_avatar_social {
      display: flex;
      flex-direction: column;
      padding-top: 15px;

      .card_avatar_name {
        font-weight: 700;
        font-size: 17px;
        line-height: 20px;
      }

      .card_social {
        display: flex;
        padding-top: 15px;
      }
    }
  }
}

.link {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  margin: auto 0;
}

.name_cell_container {
  display: flex;
  align-items: center;

  .avatar_container {
    width: fit-content;
    height: 100%;
    position: relative;

    svg {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 0;
    }
  }

  .client_info {
    margin-left: 20px;

    .name {
      font-weight: 700;
      font-size: 17px;
      line-height: 20px;
      margin-bottom: 5px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      color: $middleGray;
    }
  }
}