@import '../../../styles/index.scss';

.icon_container {
  border-radius: 50%;
  overflow: hidden;
  background-color: #F4F4F4;
  position: relative;
  .icon_image {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .icon_on_error {
    display: none;
    width: 100%;
    height: 100%;
    background-color: $middleGray;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .text {
      position: absolute;
      top:50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white
    }
  }
}

.is_error {
  .icon_image {
    display: none;
  }
  .icon_on_error {
    display: flex;
  }
}
