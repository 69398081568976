.main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 35px;
    width: 100%;

    label{
      font-family: "Inter", "Lato", "Roboto", "Poppins", "Antonio", sans-serif;
      font-style: normal;
      letter-spacing: -0.41px;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .description{
      display: flex;
      flex-direction: column;
      width: 85%;

      textarea{
        font-size: 14px;
        background-color: rgb(244, 244, 244);
        height: 100px;
        border: none;
        color: #1F1F1F;
        border-radius: 8px;
        outline: none;
        padding: 10px;
        resize: none;
      }
    }
  }

  .footer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    width: 100%;
    border-top: 0.8px solid black;

    .confirm_button{
      font-weight: 700;
      width: 85%;
      height: 50px;
      background: #858AF9;
      border-radius: 10px;
      font-size: 14px;
      padding: 13px 40px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}