@import '../../../../styles/index';

.limit_card {
  position: absolute;
  left: 0;
  z-index: -100;
  width: 100%;
  opacity: 0.15;
  background-color: #FF6A88;

}

.menu_icon {
  font-size: 18px;
  right: 5px;
  top: 5px;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  line-height: 4px;
  padding: 0px 3px;
  border-radius: 15px;
  color: $white;
  cursor: pointer;
}

.create_card_container {
  position: absolute;
  //box-shadow: 0 0 5px 1px;
  border-radius: 14px;
  overflow: hidden;
  left: 0;
  z-index: -1;
  width: 100%;
  opacity: 0.8;
  display: flex;
  justify-content: center;
  align-items: center;

}

.create_icons_container {
  position: absolute;
  //box-shadow: 0 0 5px 1px;
  border-radius: 14px;
  overflow: visible;
  right: 0;
  z-index: 300;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;

}

.card_container {
  position: absolute;
  border-radius: 10px;
  overflow: hidden;

  .card_wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 5px;
    position: relative;

    .service {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      color: #FFFFFF;

      &_box {
        display: flex;
        justify-content: space-between;

        &_info {
          &_name {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.004em;
            color: #FFFFFF;
          }
        }


        &_icon {
          transform: translate(0, -25%);
          margin-bottom: auto;
        }

      }

      .footer {
        display: flex;
        flex-direction: column;

        &_client {
          display: flex;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
        }

        &_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;

          &_hour {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            color: $white;
          }

          &_icons {
            display: flex;
            gap: 5px;
            position: relative;
            z-index: 102;

            &_icon {
              width: 18px;
              height: 18px;
              background-color: white;
              color: black;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              z-index: 300;

              svg {
                width: auto;
                max-width: 15px;
                max-height: 15px;
              }
            }

          }

        }
      }
    }

  }

  .cardSize0 {
    .service {
      &_box {
        display: none;

        &_info {
          &_name {}
        }

        &_icon {}
      }

      .footer {
        &_client {}

        &_box {
          &_hour {}

          &_icons {
            &_icon {
              &_back {
                &::before {}

                &::after {}
              }

              svg {}
            }
          }
        }
      }
    }
  }

  .cardSize1 {
    padding: 5px 5px 7px 6px;

    .service {
      justify-content: space-around;

      &_box {

        &_info {
          &_name {

            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            word-wrap: nowrap;
          }
        }

        &_icon {
          transform: translate(0, 0);

        }

      }

      .footer {

        flex-direction: row;
        align-items: flex-end;
        width: 100%;
        justify-content: space-between;

        &_client {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          word-wrap: nowrap;
        }

        &_box {
          width: -webkit-fit-content;
          width: -moz-fit-content;
          width: fit-content;

          &_hour {
            margin-right: 5px;
          }

          &_icons {
            position: relative;

            &_icon {
              position: relative;

              &_back {
                &::before {
                  position: absolute;
                  top: 1px;
                  left: 3px;
                  width: 13px;
                  height: 13px;
                  border-radius: 50%;
                  content: '';
                  background-color: #C4C4C4;
                  border: 1px solid $white;
                  z-index: -1;
                }

                &::after {
                  position: absolute;
                  top: 1px;
                  left: 5px;
                  width: 11px;
                  height: 11px;
                  border-radius: 50%;
                  content: '';
                  background-color: #C4C4C4;
                  border: 1px solid $white;
                  z-index: -2;
                }
              }

              svg {}
            }
          }
        }
      }
    }
  }

  .cardSize2 {

    .service {
      &_info {
        &_name {}
      }

      .footer {

        &_client {}

        &_box {

          &_hour {}

          &_icons {

            &_icon {


              svg {}
            }
          }
        }
      }
    }
  }

  .cardSize3 {
    .service {
      &_info {
        &_name {}
      }

      .footer {
        &_client {}

        &_box {
          &_hour {}

          &_icons {

            &_icon {
              svg {}
            }
          }
        }
      }
    }

  }

  .cardSize4 {
    .service {
      &_info {
        &_name {}
      }

      .footer {
        &_client {}

        &_box {
          &_hour {}

          &_icons {

            &_icon {
              svg {}
            }
          }
        }
      }
    }
  }

  .cardSize5 {

    .service {
      &_info {
        &_name {}
      }

      .footer {
        &_client {}

        &_box {
          &_hour {}

          &_icons {

            &_icon {
              svg {}
            }
          }
        }
      }
    }

  }
}

















.card_container_mobile {
  .card_wrapper {
    .service {

      &_box {

        &_info {
          &_name {}
        }


        &_icon {}

      }

      .footer {

        &_client {}

        &_box {

          &_hour {}

          &_icons {
            &_icon {

              svg {}
            }

          }

        }
      }
    }

  }

  .cardSize1 {
    padding: 9px 5px 8px 6px;

    .service {

      &_box {

        &_info {
          &_name {}
        }

        &_icon {}

      }

      .footer {

        &_client {}

        &_box {

          &_hour {}

          &_icons {

            &_icon {

              &_back {
                &::before {}

                &::after {}
              }

              svg {}
            }
          }
        }
      }
    }
  }

  .cardSize2 {

    .service {
      &_info {
        &_name {}
      }

      .footer {

        &_client {}

        &_box {

          &_hour {}

          &_icons {

            &_icon {


              svg {}
            }
          }
        }
      }
    }
  }

  .cardSize3 {
    .service {
      &_info {
        &_name {}
      }

      .footer {
        &_client {}

        &_box {
          &_hour {}

          &_icons {

            &_icon {
              svg {}
            }
          }
        }
      }
    }

  }

  .cardSize4 {
    .service {
      &_info {
        &_name {}
      }

      .footer {
        &_client {}

        &_box {
          &_hour {}

          &_icons {

            &_icon {
              svg {}
            }
          }
        }
      }
    }
  }

  .cardSize5 {

    .service {
      &_info {
        &_name {}
      }

      .footer {
        &_client {}

        &_box {
          &_hour {}

          &_icons {

            &_icon {
              svg {}
            }
          }
        }
      }
    }

  }
}