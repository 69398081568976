@import '../../../../styles/index';

.form{
  .body{
    display: flex;
    flex-direction: column;
    gap:5px;
    padding: 20px 25px;
  }
  .footer{
    padding: 20px 25px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
      .button{
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      width: 100%;
      height: 51px;
      font-size: 14px;
      line-height: 17px;
      background: $gradientBlue;
      color: white;
      margin-left: 5px;
    }
  }
}