@import '../../../../../../styles/index.scss';
$modal-height: 80dvh;
$mobile-modal-height: 99dvh;

.payments_modal_wrapper {
    width: 100%;
    max-height: $modal-height;

    .body {
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
        max-height: calc($modal-height - 200px);
        overflow-y: scroll;
        scroll-behavior: smooth;
        height: fit-content;
        font-family: 'Poppins';
        gap: 20px;

        .title_container {
            padding: 0px 10px;
            width: 100%;
            height: fit-content;
            font-weight: 600;
            font-size: 15px;
            line-height: 17px;
        }


        .switcher_container {
            display: flex;
            justify-content: space-between;
            padding: 0px 10px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;

        }
    }

    .footer {
        box-shadow: inset rgba(0, 0, 0, 0.2) 0px 2px 1px;
        padding: 15px 30px;
    }

    .actions_container {
        display: flex;
        justify-content: flex-end;

        .confirm_button {
            width: 100%;
            padding: 18px 40px;
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: white;
        }
    }

}