@import '../../../../../../styles/index';

.step_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 300ms ease-in;

  .step_preview {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .step_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.step_active {
  left: 0;

  .step_preview {
    animation-name: show, still, hide ;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }

  .step_container {
    animation-name: show;
    animation-duration: 500ms;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    opacity: 0;
  }

  .step_container_slide {
    animation-name: still;
    animation-delay: 0ms !important;
  }

  .step_preview_slide {
    animation-name: still, hide;
  }
}

.step_prev {
  left: -100%;
  opacity: 0;
}

.step_next {
  left: 100%;
  opacity: 0;
}

@keyframes show {
  0% {
    top: 10%;
    opacity: 0;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes still {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 0;
    opacity: 1;
  }
}

@keyframes hide {
  0% {
    top: 0;
    opacity: 1;
  }
  100% {
    top: 10%;
    opacity: 0;
  }
}

