@import '../../../styles/index.scss';

.day_selector_wrapper {
  display: flex;
  flex-direction: column;
  position: relative;

  .day_selector_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 15px;
  }

  .day_selector_item_container {
    display: flex;

    .day_selector_item {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 38px;
      min-height: 38px;
      background: $gradientLightBlue;
      border-radius: 50%;
      margin-right: 15px;
      cursor: pointer;

      .item_value {
        color: $gradientBlue;
        font-weight: 400;
        font-size: 18px;
        line-height: 17px;

        &:first-letter {
          text-transform: capitalize;
        }
      }
    }

    .day_selector_item_active {
      background: $gradientBlue;
      cursor: default;

      .item_value {
        color: $white;
      }
    }
  }

  .error_message {
    position: absolute;
    top: 100%;
    left: 0;
    color: $red;
    padding-left: 5px;
    padding-top: 5px;
  }
}

.day_selector_wrapper_like_button {
  .day_selector_title {
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    color: $lightYellow;
  }

  .day_selector_item_container {
    display: flex;

    .day_selector_item {
      border-radius: 8px;
      min-width: 107px;
      min-height: 59px;
      background: $middleGray;

      .item_value {
        color: $white;
      }
    }

    .day_selector_item_active {
      background: $gradientBlue;
      cursor: default;

      .item_value {
        color: $white;
      }
    }
  }
}

.day_selector_wrapper_mobile {
  min-width: 100%;

  .day_selector_title {}

  .day_selector_item_container {

    .day_selector_item {
      margin: auto;


      .item_value {

        &:first-letter {}
      }
    }

    .day_selector_item_active {

      .item_value {}
    }
  }

  .error_message {}
}