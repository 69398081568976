@import "../../../styles/index";

.info_container {
  position: relative;
  margin-left: 10px;
  cursor: pointer;
  width: fit-content;

  .icon_value_container {
    height: max-content;
    display: flex;
    align-items: center;
    width: max-content;

    .info_title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      white-space: nowrap;
      margin-right: 5px;
    }

    .info_icon_container {
      &:hover {
        .info_card {
          display: block;
          background: $black;
          color: $white;
          border-radius: 10px;
          padding: 10px;
          top: 100%;
          right: 0;
          min-width: 140px;
          max-width: 170px;
          position: absolute;
          z-index: 1001;

        }
      }

      .info_card {
        display: none;
      }

      .info_icon {
        width: 20px;
        height: 100%;
      }
    }

    .left_position {
      &:hover {
        .info_card {
          right: 0;
        }
      }
    }

    .right_position {
      &:hover {
        .info_card {
          left: 0;
        }
      }
    }

    .right_up_position {
      &:hover {
        .info_card {
          top: -130px;
          left: 0;
        }
      }
    }
  }
}