@import '../../../styles/index.scss';

.search_field__wrapper {
  position: relative;
  width: 100%;
  max-width: 300px;

  .search_icon {
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 15px;
  }

  .close_icon {
    width: 12px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 10px;
    cursor: pointer;
  }

  .hide {
    display: none;
  }

  .field__value {
    width: 100%;
    height: 44px;
    display: inline;
    border: none;
    border-radius: 15px;
    background-color: $mediumGray;
    width: 100%;
    padding: 8px 25px 8px 45px;
  }

  //input:focus {
  //  outline: 1px solid $blueHover;
  //}
}