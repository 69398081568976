@import '../../../../../../styles/index.scss';

* {
    border: none;


}

.container {
    display: flex;
    flex-direction: column;
    border-radius: 25px;
    background-color: $white;
    height: fit-content;

    font-family: "Poppins";

    .body {
        display: flex;
        flex-direction: column;
        width: fit-content;
        overflow-y: scroll;
        height: fit-content;
        padding: 10px 25px 20px; //5px 25px;
        align-items: center;
        width: 100%;
        //overflow-y: scroll;
        overflow: hidden;


        .card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $black;
            border-radius: 10px;
            height: 475px;
            width: 270px;
            padding: 30px;
            gap: 20px;
            position: relative;

            .head {
                display: flex;
                flex-direction: row;
                gap: 15px;
                align-items: center;
                width: 100%;
                margin: 0 0 auto 0;
                position: absolute;
                top: 5%;
            }

            .center {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 20px;
                padding: 20px;

                .logo {
                    path {
                        fill: white;
                    }
                }


                .qr_container {
                    position: relative;
                    padding: 10px;
                    width: fit-content;
                    height: fit-content;
                    background-color: white;
                    border-radius: 15px;
                    align-items: center;

                    .border {
                        position: absolute;
                        top: -9px;
                        left: -10px;
                        width: 135px;
                        height: 135px;

                        border-radius: 25px;
                        border: 3px solid white;
                    }
                }
            }

            .foot {
                position: absolute;
                bottom: 8%;
                color: $white;
                display: flex;
                justify-content: center;
                align-items: center;

                .big_text {
                    width: 100%;
                    font-size: 25px;
                    color: $white;
                    font-family: "Antonio";
                    letter-spacing: 0.0px;
                    text-transform: uppercase;
                    font-weight: 800;
                    line-height: 1;
                    text-align: center;
                }
            }

        }
    }

    .footer {
        margin: auto 0 0 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        gap: 5px;
        width: 100%;
        padding: 15px 20px;
        border-top: 1px solid #545454;

        .action_btn {
            background: $gradientBlue;
            border-radius: 10px;
            padding: 17px 30px; //20px 40px;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $white;
            cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            gap: 5px;

            .icon {
                path {
                    fill: white
                }
            }

            &:hover {
                -webkit-filter: brightness(0.8);
                filter: brightness(0.8)
            }



        }

    }

}

.container_mbl {
    height: 90dvh;
    max-height: fit-content;
    overflow-y: auto;

    .body {
        height: 100%;
        padding: 30px 25px;
        align-items: center;
        justify-content: center;

    }


    .footer {
        margin: auto 0 0 0;
    }
}