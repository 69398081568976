@import '../../../../styles/index';

.selector_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .dropdown__menu {
    width: 100%;
    position: absolute;
    z-index: 100000;
    top: 100%;
    left: 0;
    overflow: hidden;
    transition: transform 0.3s ease-out;
    transform: scaleY(1);
    transform-origin: top;
  }
}

.selector_wrapper_close {
  .dropdown__menu {
    transform: scaleY(0);
  }
}
