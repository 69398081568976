@import '../../../../styles/index';


$modal-height: 80dvh;
$mobile-modal-height: 90dvh;

.create_task_form {
  max-height: $modal-height;
  overflow: auto;

  .body {
    overflow-y: auto;
    max-height: calc($modal-height - 200px);
    scroll-behavior: smooth;
    padding: 30px;

    .inputs_wrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 30px;

      .input_wrapper {
        display: flex;
        width: 48%;
      }
    }

    .time_inputs_wrapper {
      width: 100%;
      display: flex;
      padding-bottom: 30px;

      .time_input_wrapper {
        display: flex;
        width: 48%;
        margin-right: 30px;
      }
    }

    .hide {
      display: none;
    }

    .switcher_wrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 40px;
      margin: 20px 0px 0px 40px;

      .switcher_container {
        margin-top: 10px;
        margin-left: 10px;
      }
    }
  }

  .form_actions {
    display: flex;
    justify-content: flex-end;
    padding: 20px;

    .delete_button {
      padding: 13px 40px 13px 30px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $red;
      background: $lightRed;
      display: none;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .confirm_button_block {
      .confirm_button {
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        color: white;
        margin-left: 5px;
      }

      .return_button {
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }

    }

    .edit_actions {
      justify-content: space-between;

      .delete_button {
        display: flex;
      }
    }
  }
}

.create_task_form_mobile {
  max-height: $modal-height;

  .body {
    max-height: calc($mobile-modal-height - 200px);

    .inputs_wrapper {
      flex-wrap: wrap;
      padding-bottom: 0;

      .input_wrapper {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .time_inputs_wrapper {
      flex-wrap: wrap;

      .time_input_wrapper {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }

    .switcher_wrapper {
      display: flex;
      flex-direction: column;
      padding-bottom: 20px;
      max-width: 100%;
      margin: 0px;

      .switcher_container {
        margin-top: 10px;
        margin-left: 0px;

      }
    }
  }

  .form_actions {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 20px;

    .delete_button {
      width: 100%;
      margin-bottom: 10px;
    }

    .confirm_button_block {
      display: flex;
      flex-direction: column;
      width: 100%;

      .confirm_button {
        padding: 20px 40px;
      }

      .return_button {
        margin-bottom: 10px;
      }
    }
  }

  .edit_actions {
    justify-content: space-between;

    .delete_button {
      display: flex;
    }
  }

}