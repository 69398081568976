@import 'src/styles/index.scss';



.informations {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .informations_button {
                width: 304px;
                max-width: 100%;
                color: $gradientBlue;
                background: $gradientLightBlue;

                border-radius: 10px;
                font-weight: 700;
                padding: 13px 33px;
                justify-content: flex-start;
                align-items: center;

                .icon {
                        margin-right: 5px;
                        min-width: 17px;
                        min-height: 17px;
                        max-width: 17px;
                        max-height: 17px;
                }

                &:hover {
                        path {
                                fill: $gradientLightBlue;
                        }

                        color: $gradientLightBlue;
                        background: $gradientBlue;
                        filter: brightness(0.8);
                }
        }

        h4 {
                font-weight: $font-weight-small;
                margin-bottom: 5px;
                font-size: 14px;
        }
}

.informations_mobile{
        display: flex;
        width: 100%;
        flex-direction: column;


        .informations_button {
                width: 100%;
                max-width: 100%;
                color: $gradientBlue;
                background: $gradientLightBlue;
                border-radius: 10px;
                font-weight: 700;
                padding: 13px 33px;
                margin-top: 13px;
                justify-content: center;
                align-items: center;

                .icon {
                        margin-right: 5px;
                        min-width: 17px;
                        min-height: 17px;
                        max-width: 17px;
                        max-height: 17px;
                }

                &:hover {
                        path {
                                fill: $gradientLightBlue;
                        }

                        color: $gradientLightBlue;
                        background: $gradientBlue;
                        filter: brightness(0.8);
                }
        }

        h4 {
                font-weight: $font-weight-small;
                margin-bottom: 5px;
                font-size: 14px;
        }

}