@import '../../../../styles/index.scss';

.icon__btn {
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
  justify-content: center;
  border: none;
  background: transparent;
  opacity: 0.3;

  svg {
    width: 80%;
    height: 80%;
    object-fit: contain;
  }

  &:disabled {
    cursor: default;
  }
}

.icon__btn:not(:disabled) {
  background: #1f1f1f0a;
  border-radius: 20%;
  cursor: pointer;
  opacity: 1;

  &:hover {
    background: rgba(31, 31, 31, 0.17);
  }

}