@import '../../../styles/index.scss';

.upload-file__wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &>button {
    margin-top: 30px;
    margin-left: auto;
  }

  .upload-file__btn {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 15px;

    .upload-file__input {
      width: 0.1px;
      height: 0.1px;
      opacity: 0;
      overflow: hidden;
      position: absolute;
      z-index: -1;
    }

    .btn__label {
      padding: 10px;
      width: 100%;
      height: 100%;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        height: 30px;
        margin-bottom: 15px;
      }
    }

    .error {
      margin-top: 15px;
      color: #f34245;
    }
  }

  .preview__text {
    color: $textGray;
    text-transform: uppercase;
    padding-left: 10px;
    margin: 10px 0 40px;
  }
}

.drag-container {
  height: 100px;
  width: 100%;
  border: 1px solid #DCDFE3;
  border-radius: 10px;
  display: flex;
}

.overlay {
  border-color: #f34245;
  background-color: gray;
}
