@import '../../../../../../styles/index.scss';
$modal-height: 80dvh;
$mobile-modal-height: 90dvh;

.worker_closures_container {
  display: flex;
  flex-direction: column;
  max-height: $modal-height;
  overflow: auto;

  .worker_name {
    padding: 10px 0px;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
  }

  .modal_description {
    display: none;
    padding-top: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }

  .closures_modal_wrapper {
    padding: 0px;

    .body {

      overflow-y: auto;
      max-height: calc($modal-height - 200px);
      scroll-behavior: smooth;
      padding: 30px 30px;

      .workers__menu,
      .motivation__menu {
        width: 100%;
        position: relative;
        margin-bottom: 40px;

        &_title {
          padding-bottom: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;

          span {
            padding-left: 0px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            padding-bottom: 10px;
          }
        }
      }

      .block {
        display: flex;
        width: 100%;
        gap: 40px;
        margin-bottom: 40px;
        padding-top: 20px;

        .range_picker_container {
          width: 50%;

          .date_selector_title {
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            margin-bottom: 20px;
          }

        }
      }

      .actions_container {
        display: flex;
        justify-content: flex-end;
        margin: 20px;
        padding-top: 30px;

        .confirm_button {
          padding: 13px 40px;
          border-radius: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          background: $gradientBlue;
          color: white;
          margin-left: 5px;
        }
      }

      .comment {
        width: 100%;

        &__title {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          margin-bottom: 27px;
          padding-top: 20px;

          span {

            padding-left: 0px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            padding-bottom: 10px;
          }
        }
      }
    }

    .footer {
      .actions_container {
        display: flex;
        justify-content: flex-end;
        //margin: 20px;
        padding: 15px 40px 15px;

        .confirm_button {
          padding: 13px 40px;
          border-radius: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          background: $gradientBlue;
          color: white;
          margin-left: 5px;

        }
      }
    }
  }

}

.worker_closures_container_one_day {

  .reverse_column {
    display: flex;
    flex-direction: column-reverse;
  }

  .worker_name {
    display: block;
  }

  .modal_description {
    display: block;
  }

  .closures_modal_wrapper {
    .block {
      .workers__menu {
        width: 100%;
      }

      .range_picker_container {
        display: none;
      }
    }
  }
}

.worker_closures_container_mobile {
  max-height: $mobile-modal-height ;

  .closures_modal_wrapper {
    padding: 0px;

    .body {

      max-height: calc($mobile-modal-height - 200px);

      .block {
        flex-wrap: wrap;

        .workers__menu {
          width: 100%;
        }

        .range_picker_container {
          width: 100%;
        }
      }

      .actions_container {
        margin: 0;
        padding-top: 40px;

        .confirm_button {
          width: 100%;
        }
      }
    }

    .footer {

      .actions_container {
        padding: 10px 15px 10px;

        .confirm_button {
          width: 100%;
          margin-left: 0px;
          padding: 22px 40px;

        }
      }
    }
  }
}