@import '../../../styles/index';

.mainProgressBarDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 18px;
}

.emptyProgressBar {
    background-color: $lightgray2;
    height: 100%;
    width: 100%;
    /*     overflow: hidden;
    position: relative;*/

}

.fillingProgressBar {
    height: 100%;

    //position: absolute;
}