@import '../../../../../styles/index.scss';

.main{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 35px;
    width: 100%;

    label{
      font-family: "Inter", "Lato", "Roboto", "Poppins", "Antonio", sans-serif;
      font-style: normal;
      letter-spacing: -0.41px;
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .phone{
      display: flex;
      flex-direction: column;
      width: 85%;

      .input_phone{
        font-size: 14px;
        background-color: rgb(244, 244, 244);
        height: 50px;
        border: none;
        color: #1F1F1F;
        border-radius: 8px;
        outline: none;
        padding: 10px
      }
    }

    .verified{
      display: flex;
      flex-direction: column;
      width: 85%;
      margin-top: 35px;

      .verified_input{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .verified_text {
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          color: #787878;
        }
      }
    }
  }

  .footer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.8px;
    width: 100%;
    border-top: 1px solid black;

    .confirm_button{
      font-weight: 700;
      width: 85%;
      height: 50px;
      background: $gradientBlue;
      border-radius: 10px;
      font-size: 14px;
      padding: 13px 40px;
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}

.checkbox_wrapper_17 input[type=checkbox] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.checkbox_wrapper_17 label {
  --size: 50px;

  cursor: pointer;
  width: var(--size);
  height: calc(var(--size) / 2);
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.checkbox_wrapper_17 label:after {
  content: '';
  position: absolute;
  top: 6%;
  left: 2.5%;
  width: calc(50% - 5%);
  height: calc(100% - 11%);
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.checkbox_wrapper_17 input:checked + label {
  background: #848AF8;
}

.checkbox_wrapper_17 input:checked + label:after {
  left: calc(100% - 2.5%);
  transform: translateX(-100%);
}

.checkbox_wrapper_17 label:active:after {
  width: 55%;
}