@import '../../../../../../styles/index.scss';

.closures_workers_container {
  padding: 40px 60px 80px;

  .closures_add_button {
    display: none;
    position: absolute;
    z-index: 10;
    top: 80%;
    right: 50px;
    cursor: pointer;
  }

  .tables_actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 70px 2% 20px 2%;

    .picker_container {
      min-width: 150px;
    }

    .name_cell_container {
      display: flex;
      align-items: center;

      .client_info {
        margin-left: 20px;

        .name {
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
          margin-bottom: 5px;
        }

        .description {
          font-weight: 400;
          font-size: 14px;
          color: $middleGray;
        }
      }
    }
  }

}

.empty_card {
  padding: 20px 25px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $middleGray;
}

.closures_workers_container_mobile {
  padding: 30px 18px 30px;

  .closures_add_button {
    display: block;
  }

  .tables_actions {
    width: 100%;
    flex-wrap: wrap;

    .picker_container {
      width: 100%;
      padding-top: 30px;
    }

    .name_cell_container {
      width: 100%;
    }
  }
}

.closures_cards_list_container {
  .closures_card {
    padding: 30px 20px;
    border-bottom: 1px solid $borderColor;

    &:last-child {
      border-bottom: none;
    }

    .card_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 17px;

      .row_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-right: 10px;
      }

      .row_value {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $middleGray;
      }
    }

    .delete_button {
      padding: 13px 40px;
      margin-top: 20px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $red;
      background: $lightRed;
      width: 100%;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }
}