@import '../.././../../../styles/index.scss';

.container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 20px 35px;
    gap: 15px;
    border-radius: 15px;
    background-color: white;
    margin-bottom: 30px;

    .title {
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 600;
    }


    .item {
        width: 100%;
        display: flex;
        justify-content: space-between;

        span {
            font-size: 13px;
            font-weight: normal;
            line-height: 17px;
            vertical-align: middle;
        }

        .saldo {
            width: fit-content;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            span {
                font-size: 14px;
                line-height: 20px;
            }

            .arrow {
                width: 10px;
                margin-left: 15px;
                margin-bottom: 1%;
                opacity: 0.8;

                path {
                    fill: $black;
                }
            }

            .highlight {
                color: $gradientBlue;
            }
        }

    }


}

.container_mbl {
    padding: 20px 20px 20px 15px;
}

.modal {
    width: 400px;
    min-height: 200px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;

    .head {
        display: flex;
        flex-direction: column;
        gap: 5px;
        padding-bottom: 10px;
        border-bottom: 1px solid $black;

        h1 {
            font-family: 'Antonio';
            font-size: 48px;
            font-weight: 700;
            line-height: 50px;
            color: $gradientBlue;
        }

        span {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            color: $black;
        }
    }

    .details {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .info {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            span {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                color: $black;
            }
        }
    }

    .footer {
        width: 100%;
        text-align: right;
        font-size: 12px;
        font-weight: 400;
        line-height: 17px;
        color: $black;
        opacity: 0.6;
    }


}

.modal_mbl {
    width: 100%;
    height: 70vh;
}

.close {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}