@import '../../../styles/index.scss';

.table_container {
  width: 100%;

  hr {
    width: 100%;
    border: 1px solid black;
  }

  .empty_table {
    display: flex;
    padding: 20px 0px 0px 60px;
    height: 100%;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: $middleGray;
  }

  .table_header {
    padding: 20px 0 20px 60px;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    border-bottom: 1px solid $borderColor;
  }

  .table_cell {
    border-bottom: 1px solid $borderColor;
    padding: 20px 0 20px 60px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $middleGray;
  }
}

.pagination_wrapper {
  display: flex;
  padding: 45px 35px 45px;
  justify-content: flex-end;

  .pagination_container {
    display: flex;
    align-items: center;

    .page_count {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      padding-right: 20px;
    }

    .arrow_block {
      margin-right: 20px;

      .arrow_button {
        padding: 0;
        margin-right: 10px;
        height: 40px;
        min-width: 40px;
        background-color: $mediumGray;
        border-radius: 10px;

        &:not(:disabled):hover {
          filter: brightness(0.8);
        }
      }
    }
  }
}

.pagination_wrapper_mobile {
  padding: 20px 14px;
  .pagination_container {
    .arrow_block {
      margin-right: 0;
    }
  }
}

.table_container_mobile {
  .table_header {
    padding: 20px 0 20px 20px;
  }

  .table_cell {
    padding: 20px 0 20px 20px;
  }
}