@import '../../../../styles/index.scss';

.cards_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: fit-content;
  padding-right: 40px !important;

  .card_item_wrapper {
    width: 32%;
    margin-left: 20px;
    box-shadow: 0px 5px 10px -10px #6C7982;
    border-radius: 14px;
    background-color: $white;
    padding: 20px;

    &:first-child {
      margin-left: 0;
    }

    .card_name {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 30px;
    }

    .card_values_container {
      display: flex;
      flex-direction: column;

      .card_value_item {
        padding-bottom: 20px;

        .item_title {
          padding-bottom: 4px;
        }

        .item_values_wrapper {
          display: flex;
          justify-content: space-between;

          .item_count {
            font-weight: 500;
            font-size: 20px;
          }

          .value_and_info_container {
            display: flex;

            .value_wrapper {
              padding: 2px 7px;
              border-radius: 20px;

              .value_count {
                font-size: 20px;
              }
            }

            .disabled {
              display: none;
            }

            .percent {
              background: linear-gradient(180deg, #FFF7F7 0%, #FFF4F7 100%);

              .value_count {
                background: $gradientRed;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }

            .positive {
              background: linear-gradient(180deg, #F8FFE4 0%, #F2FFDD 100%);

              .value_count {
                background: linear-gradient(180deg, #A5CE00 0%, #58AD02 100%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
              }
            }

            .info_container {
              position: relative;
              margin-left: 10px;
              cursor: pointer;

              .info_icon {
                width: 20px;
                height: 100%;
              }

              &:hover {
                .info_card {
                  display: block;
                  position: absolute;
                  z-index: 10;
                  background: $gradientBlue;
                  border-radius: 10px;
                  padding: 10px;
                  top: 100%;
                  right: 0;
                  box-shadow: 0px 5px 10px -10px #6C7982;
                  width: 200px;
                  height: 100px;
                }
              }

              .info_card {
                display: none;
              }
            }
          }
        }
      }
    }
    .disabled_card_value {
      display: none;
      font-size: 20px;
    }
  }
  .disabled_card {
    background: $lightgray;
    opacity: .5;
    min-height: 270px;
    .card_values_container {
      display: none;
    }
    .disabled_card_value {
      display: block;
    }
  }
}

.cards_container_mobile {

}
