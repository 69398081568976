@import '../../../../../../styles/index';

.calendar_day_selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 150px;
  margin-right: 15px;

  .day_value {
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    padding: 0 10px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  .calendar_arrow_button {
    padding: 0;
    margin-right: 10px;
    height: 40px;
    min-width: 40px;
    background-color: $mediumGray;
    border-radius: 10px;

    &:hover {
      background-color: $mediumGray;
    }

    .arrow_left {
      border: solid $black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      cursor: pointer;
    }

    .arrow_right {
      border: solid $black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      cursor: pointer;
    }
  }
}