@import '../../../../styles/index';

.queue_action_container {
  position: absolute;
  top: 85%;
  right: 5%;
  z-index: 20;
  display: flex;

  .create_task_selector {
    position: relative;
    .tooltip {
      position: absolute;
      background: #FFFFFF;
      border-radius: 14px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      gap: 5px;
      bottom: 0px;
      transform: translate(-75%, -50%);
      :disabled{
        filter: brightness(0.8);
        cursor: not-allowed;
      }
      &_pause {
        width: 150px;
        height: 50px;
        background: $gradientLightBlue;
        color: $gradientBlue;
        border-radius: 10px;
        font-weight: 700;

      }

      &_new {
        font-weight: 700;
        width: 150px;
        height: 50px;
        background: $gradientBlue;
        border-radius: 10px;
      }
    }
  }

  .queue_button {
    width: fit-content;
    display: flex;
    border-radius: 10px;
    padding: 13px 35px 13px 25px;
    margin-right: 25px;
    box-shadow: none;
    background: $gradientLightBlue;

    &_icon {
      :hover {
        filter: brightness(0.8);
      }
    }

    &_value {
      color: #6984FF;
      margin-left: 5px;
    }
  }

  svg {
    cursor: pointer;
  }
}

.queue_action_container_hide {
  display: none;
}