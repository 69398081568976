@import '../../../../styles/index.scss';

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    align-items: center;
    overflow: auto;
    gap: 20px;
    padding-top: 18vh;

    .logo {
        width: 130px;
        display: block;
        margin-top: 50px;
    }

    .container_icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        height: fit-content;
        padding: 10px;
        margin-bottom: 50px;
        border-radius: 50%;
        background-color: $gradientLightBlue;
        box-shadow: 0px 0px 2px 10px rgba($gradientLightBlue, 0.5);

        .icon {
            width: 30px;
            height: 30px;

            path {
                fill: $gradientBlue;
            }
        }
    }

    .success {
        background-color: rgba(#05DCA9, 0.24);
        box-shadow: 0px 0px 2px 10px rgba(#05DCA9, 0.12);

        .icon {
            path {
                fill: #02AD84;
            }
        }
    }

    h1 {
        font-family: 'Poppins';
        font-weight: 700;
        text-align: center;
        font-size: 30px;
    }

    .description {
        max-width: 80%;
        text-align: center;
        font-size: 16px;
        font-weight: normal;
        line-height: 20px;
        color: $middleGray;
    }

    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 25px 20px 20px;
        width: 100vw;
        max-width: 375px;
        border-radius: 16px;
        gap: 10px;
    }

    p {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $middleGray;
    }

    .highlight {
        color: $gradientBlue;
        cursor: pointer;
    }

    .confirm_button {
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        color: white;
        width: 100%;
        margin: 0;
    }

}

.container_mbl {
    h1 {
        font-size: 30px;
    }
}