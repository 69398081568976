@import '../../../../../../../styles/index';

.button_container {
  display: flex;
  align-items: center;
  width: fit-content;
  height: fit-content;

  .button_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    color: white;
    padding-right: 20px;
  }

  .button_icon {
    cursor: pointer;
    width: 44px;
    height: 44px;
    background-color: $gradientBlue;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.button_container_disabled {
  opacity: .3;
}