@import '../../../../../../styles/index.scss';

* {
    font-family: 'Poppins';
    font-weight: 300;

    li {
        font-size: 15px;
    }
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    background-color: transparent;

    .body {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: fit-content;
        background-color: $white;
        border-radius: 14px;
        padding: 20px 30px;
        gap: 10px;

        .field {
            width: 48%;
            //min-width: fit-content;

            &_little {
                width: 30%;
            }
        }

        .p {
            font-weight: 300;
            font-size: 15px;
            line-height: 1.5;
        }

        .error_text {
            display: flex;
            color: $red;
            font-size: 12px;
            line-height: 1.2;
            padding-left: 10px;
            margin-bottom: 10px;
        }
    }

    .footer {
        padding: 20px 30px;
    }

    .actions_container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;


        .confirm_button {
            border-radius: 10px;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            background: $gradientBlue;
            color: white;
            margin-left: 5px;
            padding: 13px 30px;
            border-radius: 10px;
            background-color: $gradientBlue;
        }

        .icon {
            margin-right: 5px;
            min-width: 17px;
            min-height: 17px;
            max-width: 17px;
            max-height: 17px;

            path {
                fill: white;
            }
        }

        .secondary_button {
            margin-left: 5px;
            padding: 13px 30px;
            background-color: black;
        }
    }

    .text_section {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 10px 0px 30px;

        .title {
            font-weight: 800;
            font-size: 48px;
            font-family: 'Antonio';
            line-height: 1.5;

        }

        .p {
            font-weight: 300;
            font-size: 15px;
            line-height: 1.5;
        }

    }


}


.container_mobile {
    .body {
        .field {
            width: 100%;
            //min-width: fit-content;

            &_little {
                width: 25%;
                min-width: none;
            }
        }
    }

    .footer {
        padding: 15px 30px;
    }


    .text_section {
        margin: 20px 10px 0px;
        width: fit-content;

        .h1 {
            font-size: 43px;
        }
    }
}