@import '../../../../styles/index';

.menu_wrapper {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 202px;
  border-radius: 10px;
  border: 1px solid #00000022;
  overflow-y: auto;
  background-color: $white;

  .option_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    cursor: pointer;
    border-bottom: 1px solid $borderColor;
    padding-left: 20px;
    padding-right: 20px;

    &:nth-last-child(1) {
      border-bottom: 1px solid transparent;
    }

    &:hover {
      background: $lightgray2;
    }

    .option_value_wrapper {
      display: flex;
      align-items: center;
      overflow: auto;

      .section_value {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }

      .option_value {
        font-weight: 500;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .second_option {
        font-weight: normal;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        flex-direction: row;
        gap: 5px
      }

      .value_icon {
        padding: 10px 10px 10px 5px;
      }

      .value_color {
        width: 33px;
        height: 33px;
        border-radius: 10px;
        margin-right: 10px;
      }
    }

    .radio_button {
      height: 20px;
      width: 20px;
      min-height: 20px;
      min-width: 20px;
      position: relative;
      accent-color: transparent;
      cursor: pointer;

      &:checked {
        accent-color: transparent;
      }

      &:after {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        content: '';
        visibility: visible;
        background: $white;
        outline: 1px solid #0000007f;
      }

      &:checked:after {
        width: 12px;
        height: 12px;
        border-radius: 15px;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        position: absolute;
        background-color: $gradientBlue;
        content: '';
        display: inline-block;
        visibility: visible;
      }

      &:checked:before {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;
        content: '';
        visibility: visible;
        background: $white;
        outline: 1px solid #0000007f;
      }

    }

    .select_button {
      &:checked {
        accent-color: transparent;
      }

      &:after {
        border-radius: 20%;
      }

      &:checked:after {
        border-radius: 20%;
        background-color: $gradientBlue;
      }

      &:checked:before {
        border-radius: 20%;
      }

    }
  }


  .option_item_blocked {
    background: $lightgray;
    cursor: default;
    opacity: 0.5;

    &:hover {
      background: $lightgray;
    }

    .radio_button {
      cursor: default;
    }
  }

  .option_item_disabled {
    background: $white;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);

    cursor: default;
    opacity: 0.3;

    &:hover {
      -webkit-filter: grayscale(1);
      filter: grayscale(1);
      background: $white;

    }

    .radio_button {
      -webkit-filter: brightness(0.8);
      filter: brightness(0.8);
      cursor: default;
    }
  }
}

.menu_wrapper_fullHeight {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  overflow-y: auto;

  .error_message {
    top: 100%;
    left: 0;
    position: absolute;
    color: $red;
    padding-top: 5px;
    text-align: center;
  }
}

.menu_wrapper_border {
  border: none;
}

.menu_wrapper_dark_mode {
  background-color: $black;

  .option_item {

    &:hover {
      background: $lightgray;
    }

    .option_value_wrapper {
      display: flex;
      align-items: center;
      overflow: auto;

      .option_value {
        color: $white;
      }
    }

    .radio_button {

      &:after {
        background: #3A3A3A;
        outline: 1px solid $white;
      }

      &:checked:after {
        background-color: $gradientBlue;
      }

      &:checked:before {
        background: #3A3A3A;
        outline: 1px solid $white;
      }

    }
  }
}