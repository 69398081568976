@import '../../../../styles/index.scss';

.cards_container {
  padding-bottom: 60px;

  .shop_section {
    justify-content: space-between;
    display: flex;
    width: 100%;
    background-color: $white;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 20px;

    .shop_img {
      width: 60%;
    }

    .mobile_img_block {
      display: none;
    }

    .shop_info {
      position: relative;
      width: 40%;
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .shop_icon {
        display: none;
      }

      .shop_button {
        width: fit-content;
        color: $white;
        background-color: $black;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 40px;
        border-radius: 10px;
      }
    }
  }

  .social_section {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .social_card {
      width: 49%;
      min-width: 390px;
      margin-bottom: 20px;
      background-color: $white;
      border-radius: 14px;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;

      .social_card_header {
        padding: 20px 50px 30px;
      }

      .social_img {
        width: 100%;
      }

      .social_button {
        position: absolute;
        bottom: 20px;
        left: 40px;
        width: fit-content;
        color: $white;
        background-color: $black;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 40px;
        border-radius: 10px;
      }
    }
  }

  .interest_section {
    justify-content: space-between;
    display: flex;
    width: 100%;
    background-color: $white;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 20px;

    .interest_img_block {
      width: 60%;
      display: flex;
      justify-content: space-between;
      padding: 17px;

      .shop_img {
        width: 32%;
        border-radius: 20px;
      }
    }

    .interest_info {
      position: relative;
      width: 40%;
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 50px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .article_icon {
        display: none;
      }

      .interest_button {
        width: fit-content;
        color: $white;
        background-color: $black;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 40px;
        border-radius: 10px;
      }
    }
  }

  .info_block {
    .info_title {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      padding-bottom: 20px;
    }

    .info_description {
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: $middleGray;
    }
  }
}

.cards_container_mobile {
  .shop_section {
    flex-direction: column;
    padding: 13px;

    .shop_img {
      display: none;
    }

    .mobile_img_block {
      display: flex;
      justify-content: space-between;

      img {
        width: 30%;
        border-radius: 10px;
      }
    }

    .shop_info {
      width: 100%;
      padding-top: 20px;
      padding-left: 40px;
      .shop_button {
        display: none;
      }

      .shop_icon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 20px;

        svg {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  .social_section {
    .social_card {
      width: 100%;
      min-width: 100%;
      margin-bottom: 20px;
      background-color: $white;
      border-radius: 14px;
      position: relative;
      overflow: hidden;
      flex-wrap: wrap-reverse;

      .social_card_header {
        padding: 20px 24px 30px;
      }

      .social_img {
        width: auto;
        height: 300px;
        object-fit: cover;
      }

      .social_button {
        display: none;
      }
    }
  }

  .interest_section {
    justify-content: normal;
    display: flex;
    flex-wrap: wrap-reverse;
    width: 100%;
    background-color: $white;
    border-radius: 14px;
    overflow: hidden;
    margin-bottom: 20px;

    .interest_img_block {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-bottom: 0;

      .shop_img {
        width: 32%;
        border-radius: 20px;
      }
    }

    .interest_info {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .article_icon {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        padding-top: 20px;
        padding-left: 20px;
        svg {
          width: 28px;
          height: 28px;
        }
      }

      .interest_button {
        display: none;
      }
    }
  }
}
