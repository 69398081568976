@import '../../../../styles/index.scss';

.container_toast {

        h3,
        h4 {
                font-weight: $font-weight-small;
                margin-bottom: 5px;
                font-size: 17px;
        }

        h4 {
                color: $middleGray;
                font-size: 14px;

        }

        &_icons {
                padding-top: 10px;
                display: flex;
                flex-wrap: wrap;
                gap: 10px;

        }
}