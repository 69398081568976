@import '../../../../styles/index.scss';

.tables_container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;

  .table_container {
    width: 49%;
    //min-width: 390px;
    height: fit-content;
    box-shadow: 0px 5px 10px -10px #6C7982;
    border-radius: 14px;
    background-color: $white;
    margin-bottom: 20px;

    .table_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px 35px;
      border-bottom: 1px solid $borderColor;

      .header_label {
        display: flex;
        align-items: center;

        svg {
          width: 28px;
          height: 100%;
        }

        .label_text {
          font-weight: 700;
          font-size: 20px;
          margin-left: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .day_selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 150px;

        .day_value {
          font-weight: 500;
          font-size: 17px;
          padding: 0 10px;
        }
      }
    }

    .table_footer {
      height: fit-content;
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
      justify-content: flex-end;
      align-items: center;

      .day_selector {
        display: none;
        justify-content: space-between;
        align-items: center;
        min-width: 150px;
        padding-left: 30px;

        .day_value {
          font-weight: 500;
          font-size: 17px;
          padding: 0 10px;
        }
      }

      .arrow_block {
        margin-right: 20px;

        .arrow_button {
          padding: 0;
          margin-right: 10px;
          height: 40px;
          min-width: 40px;
          background: $mediumGray;
          border-radius: 10px;

          &:not(:disabled):hover {
            filter: brightness(0.8);
          }
        }
      }

      .pagination_value {
        display: none;
      }
    }

    .table_clients {
      height: 230px;

      .client_item {
        height: 20%;
        border-bottom: 1px solid $borderColor;
        display: flex;
        align-items: center;
        padding: 0 20px;
        justify-content: space-between;

        .description {
          margin-left: 10px;
          width: 80%;
          display: flex;

          .name {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            width: 50%;
          }

          .value {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            width: 50%;
          }
        }

        .client_actions {
          display: flex;
          flex-wrap: nowrap;

          //&_icon {
          //  width: 38px;
          //  height: 38px;
          //  display: flex;
          //  align-items: center;
          //  justify-content: center;
          //
          //  &:hover {
          //    background-color: $lightgray;
          //    border-radius: 20%;
          //
          //  }
          //
          //  svg {
          //    width: 25px;
          //    height: 100%;
          //    cursor: pointer;
          //  }
          //}

        }

        .menu_icon {
          display: none;
          font-size: 18px;
          color: $middleGray;
          cursor: pointer;
        }
      }

      .empty_message {
        padding: 10px;
        color: $middleGray;
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .arrow_left {
      border: solid $black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(135deg);
      cursor: pointer;
    }

    .arrow_right {
      border: solid $black;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(-45deg);
      cursor: pointer;
    }
  }

}

.tables_container_mobile {
  .table_container {
    width: 100%;

    .table_header {
      .day_selector {
        display: none;
      }
    }

    .table_footer {
      justify-content: space-between;

      .day_selector {
        display: flex;
      }

      .pagination_value {
        display: block;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-left: 18px;
      }

    }

    .table_clients {
      height: 320px;

      .client_item {
        .menu_icon {
          display: block;
        }

        .avatar_with_description {
          display: flex;
          align-items: center;
          width: 100%;

          .description {
            width: 100%;
            margin-left: 10px;
            display: flex;
            flex-direction: column;

            .name {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
            }

            .value {
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
}