@import '../../../styles/index';

.picker_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  .error_message {
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%, 0);
    color: $red;
    padding-top: 20px;
  }
  .day_name_value {
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: $middleGray;
    text-transform: uppercase;
  }
}
