@import '../../../../../../styles/index.scss';
$modal-height: 80dvh;
$mobile-modal-height: 90dvh;

.worker_modal_wrapper {
  width: 100%;
  max-height: $modal-height;
  overflow: auto;

  .body {
    padding: 20px 30px;
    max-height: calc($modal-height - 200px);
    overflow-y: auto;
    scroll-behavior: smooth;
    height: fit-content;
  }

  .footer {
    padding: 15px 50px;
  }

  .actions_container {
    display: flex;
    justify-content: flex-end;

    .confirm_button {
      width: 100%;
      padding: 18px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $gradientBlue;
      color: white;
    }
  }
}

.worker_modal_wrapper_mobile {
  max-height: $mobile-modal-height;

  .body {
    max-height: calc($mobile-modal-height - 200px);
  }

  .footer {
    padding: 15px 30px;
  }
}