@import '../../../../styles/index.scss';

.services_container {
  padding: 40px 60px 80px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .service_add_button {
      cursor: pointer;
    }
  }

  .button_selector_container {
    display: flex;
    align-items: center;
    margin-top: 35px;

    .button_selector_title {
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      margin-right: 20px;
    }
  }

  .services_list_container {
    padding-bottom: 40px;
    margin-top: 40px;

  }
}

.services_container_mobile {
  padding-left: 15px;
  padding-right: 15px;

  .header {

    .service_add_button {
      position: absolute;
      z-index: 10;
      top: 82%;
      right: 30px;
    }
  }

  .button_selector_container {
    flex-direction: column;
    align-items: normal;

    .button_selector_title {
      margin-bottom: 10px;
    }

    .filters_container {
      flex-wrap: wrap;
      white-space: nowrap;
      width: calc(100vw - 0px);
      transform: translate(-15px, 0);
      overflow-x: auto;
      z-index: 20;

      &::-webkit-scrollbar {
        display: none;
      }

      .button_wrapper {
        padding-bottom: 20px;
      }
    }
  }

  .services_list_container {}
}