.main{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .question{
    font-size: 16px;
  }

  .nota{
    font-size: 12px;
    margin-top: 30px;
  }
}

