.page-wrapper {
  width: 100%;
  height: 100%;

  position: fixed;


  .content-wrapper {
    display: flex;
    height: calc(100% - (65px));

    .section__wrapper {
      width: 100%;
      height: 100%;
      overflow: auto;


      &>section {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.page-wrapper_mobile_calendar {
  .content-wrapper {
    height: calc(100% - (130px));
  }
}