@import '../../../styles/index.scss';

.switcher__wrapper {
  width: 100%;
  display: flex;

  .switcher__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 51px;
    margin-right: 10px;
    border-radius: 10px;
    background: $gradientBlue;
    cursor: default;

    .switcher__value {
      font-size: 13px;
      color: $white;
      font-weight: 700;
    }

  }

  .is_active {
    color: white;
    background: $gradientBlueOpacity;
    cursor: pointer;

    .switcher__value {
      background: $gradientBlue;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
}

.switcher__wrapper_full_width {
  justify-content: space-between;

  .switcher__button {
    width: 48%;
    justify-content: center;
    margin-right: 0px;
  }
}

.switcher__wrapper_dark_mode {
  .switcher__button {

    .switcher__value {
      color: $white;
      font-weight: 400;
      font-size: 25px;
    }
  }

  .is_active {
    background: $middleGray;

    .switcher__value {
      color: $white;
      font-weight: 400;
      font-size: 25px;
    }
  }
}

.switcher__wrapper_mini {
  .switcher__button {
    width: 46px;
    height: 41px;
    margin-left: 10px;
    margin-right: 0;
  }
}