@import '../../../../styles/index.scss';

.client_modal_container {
  max-height: 90vh;

  .client_modal_body {
    overflow-y: auto;
    padding: 20px 50px;
    max-height: calc(90vh - 200px);

    .avatar_and_status_block {
      display: flex;
      justify-content: space-between;

      .avatar_block {
        display: flex;
        align-items: center;
        margin-bottom: 40px;

        .avatar_container {
          width: fit-content;
          height: 100%;
          position: relative;

          svg {
            position: absolute;
            width: 25px;
            height: 25px;
            right: 0;
          }
        }


        .client_name {
          margin-left: 20px;
          font-style: normal;
          font-weight: 700;
          font-size: 17px;
          line-height: 20px;
        }
      }

      .status_selector_container {
        width: 290px;
      }
    }

    .info_cell {
      padding-top: 25px;
      display: flex;
      justify-content: space-between;

      .info_title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 10px;
      }

      .aggiungi_nota {
        color: #9293D7;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        cursor: pointer;
      }

      .info_value {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: $middleGray;
        display: flex;
        align-items: center;

        .phone {
          color: $middleGray;
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }

    .bad_section {
      display: flex;
      gap: 8px;
      padding: 12px 8px;
      border-radius: 4px;
      background: rgb($red, 0.25);
      border: 1px solid $red;
      border-left: 4px solid $red;
      color: $red;

      .info_cell {
        flex-direction: column;
        justify-content: flex-start;

        padding: 0;
        margin: 0;

        .info_title {
          color: $red;
        }
      }
    }

    .good_section {
      display: flex;
      gap: 8px;
      padding: 12px 8px;
      border-radius: 4px;
      background: rgb($greenDark, 0.15);
      border: 1px solid $greenDark;
      border-left: 4px solid $greenDark;
      color: $greenDark;

      .info_value_section{
        color: $greenDark;
      }

      .info_cell {
        flex-direction: column;
        justify-content: flex-start;

        padding: 0;
        margin: 0;

        .info_title {
          color: $greenDark;
        }
      }
    }

    .info_icons_container {
      display: flex;
    }

    .active_appointments_block {
      display: flex;
      flex-direction: column;

      .appointment_container {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        margin-bottom: 12px;
        border-bottom: 1px solid $borderColor;

        .appointment_info {
          display: flex;
          flex-direction: column;

          .appointment_info_value {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: $middleGray;
            padding-bottom: 10px;

            &:first-letter {
              text-transform: capitalize;
            }
          }
        }

        .appointment_action {
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          padding-right: 5px;
          cursor: pointer;
        }

        &:last-child {
          padding-bottom: 0;
          margin-bottom: 0;
        }
      }
    }

    .statistica_block {
      display: flex;
      flex-direction: column;
      height: fit-content;

      .statistica_info {
        display: flex;
        padding-bottom: 10px;

        .statistica_title {
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $middleGray;
          margin-right: 4px;
        }

        .statistica_value {
          font-weight: 800;
          font-size: 14px;
          line-height: 17px;
          color: $middleGray;
        }
      }
    }

    .appointments_history_block {
      display: flex;
      flex-direction: column;

      .appointments_history_row {
        display: flex;
        margin-bottom: 12px;
        align-items: center;

        .appointments_history_info_cell {
          width: 40%;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $middleGray;
        }

        .edit_note {
          padding: 5px;
          width: fit-content;
          border-radius: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: black;
          background: #F6F6F6;
          cursor: pointer;
          margin-right: 5px;

          &:hover {
            background: #CCC6C6;
          }

          svg {
            width: 20px !important;
            height: 20px !important;
          }
        }

        .delete_note {
          padding: 5px;
          width: fit-content;
          border-radius: 10px;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: $red;
          background: $lightRed;
          cursor: pointer;

          &:hover {
            background: #CCC6C6;
          }

          svg {
            width: 20px !important;
            height: 20px !important;
          }
        }

        .note_cell {
          display: flex;
          justify-content: flex-end;
        }

        .appointments_history_date_cell {
          width: 20%;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: $middleGray;
          text-align: right;
        }
      }
    }
  }

  .appointment_title {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 10px;
    padding-top: 35px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 50px;
    gap: 15px;
    overflow: visible;


    .title_wrapper {
      display: flex;
      align-items: center;

      .title {
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
      }
    }

    .delete_button {
      padding: 13px 40px 13px 30px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: $red;
      background: $lightRed;

      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }

    .enable_button {
      padding: 13px 40px;
      border-radius: 10px;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      background: $gradientBlue;
      color: white;
      margin: 5px;
    }
  }
}

.client_modal_container_mobile {
  .client_modal_body {
    padding: 20px;

    .avatar_and_status_block {
      flex-direction: column;

      .avatar_block {
        width: 100%;
      }

      .status_selector_container {
        width: 100%;
        padding-bottom: 30px;
      }
    }

    .appointments_history_block {
      .appointments_history_row {
        display: flex;
        flex-wrap: wrap;

        .appointments_history_info_cell {
          width: 100%;
        }

        .appointments_history_date_cell {
          width: 100%;
          text-align: left;
        }
      }
    }
  }

  .footer {
    padding: 20px;
  }
}