@import '../../../../../../../styles/index';

.salon_step_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 15%;

  .salon_step_content {
    display: flex;
    flex-direction: column;
    width: 60%;
    .step_description {
      font-weight: 700;
      font-size: 48px;
      line-height: 46px;
      color: $lightYellow;
      text-transform: uppercase;
    }
    .step_children {

    }
  }
}