@import '../../../../styles/index.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 20px 20px;
    max-width: 100%;
    border-radius: 16px;
    gap: 20px;
    position: relative;

    .close_icon {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        width: 11px;
        height: 11px;
    }

    h2 {
        width: 100%;
        text-align: left;
        font-size: 18px;
        font-weight: 700;
        color: $black;
        margin: 0;
        padding: 0px 8px;
    }

    .description {
        text-align: left;
        padding: 0px 8px;
        font-size: 16px;
        width: 100%;
        font-weight: 400;
        line-height: 20px;

        p {
            margin: 10px 0px 0px;
            padding: 0;
            color: $black;
            font-size: 14px;
        }


        span {
            font-weight: 600;
            font-size: 16px;
            color: $gradientBlue;
            text-decoration: none;
            cursor: pointer;

        }
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 10px;
        margin-top: 20px;

        .confirm_button {
            font-weight: 700;
            height: 50px;
            width: 100%;
            background: $gradientBlue;
            border-radius: 10px;
            font-size: 14px;
            padding: 13px 40px;
            margin: 0;
        }

        .secondary {
            padding: 5px;
            background: #F6F7F8;
            color: $black;
            height: fit-content;
            font-size: 12px;
            font-weight: 500;
        }
    }

}

.container_mbl {
    border-radius: 0px;

    .close_icon {
        width: 13px;
        height: 13px;
    }

    h2 {
        margin-top: 40px;
    }
}