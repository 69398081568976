@import '../../../../../../styles/index.scss';

* {
    font-family: 'Poppins';
    font-weight: 300;

    li {
        font-size: 15px;
    }
}

.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: fit-content;
    justify-content: space-between;
    background-color: transparent;

    .body {
        display: flex;
        flex-direction: column;
        height: 100%;
        background-color: $white;
        border-radius: 14px;
        padding: 20px 30px;
        gap: 35px;
        width: 60%;

        .field {
            min-width: fit-content;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;

            .text_ul {
                font-weight: 700;
                font-size: 30px;
                font-family: 'Antonio';
            }

            .text {
                font-size: 15px;
            }

            .icon {
                margin-left: auto;

                path {
                    fill: black;
                }
            }
        }

        .p {
            font-weight: 300;
            font-size: 15px;
            line-height: 1.5;
        }


    }

    .imgSection {
        width: 40%;
        height: fit-content;

        .img {
            width: 80%;
            height: auto;
        }

    }

    .footer {
        min-width: fit-content;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 20px 30px;
        gap: 20px;
        width: 60%;

        .text_ul {
            font-weight: 700;
            font-size: 30px;
            font-family: 'Antonio';
        }

        .text {
            font-size: 15px;
        }

        .icon {
            margin-left: auto;

            path {
                fill: black;
            }
        }
    }

}


.container_mobile {
    // flex-wrap: wrap;

    .body {
        width: 100%;
        padding: 15px 20px;

    }

    .imgSection {
        width: 100%;

        .img {
            width: 100%;
        }
    }

    .footer {
        width: 100%;
        padding: 15px 20px;

        .text {
            font-size: 13px;
        }
    }

}