@import '../../../styles/index';

.picker_input_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.selector_container {
  min-width: 150px;
  width: fit-content;
  background-color: white;
  border: 1px solid $borderColor;
  border-radius: 10px;
  padding: 17px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;

  .value {
    font-weight: 700;
    font-size: 14px;
    margin-right: 10px;
  }

  .arrow {
    border: 5px solid transparent;
    border-bottom: 5px solid $black;
    margin-top: 0;
    margin-bottom: 5px;
  }
}

.selector_container_close {
  .arrow {
    margin-top: 5px;
    margin-bottom: 0;
    transform: rotate(180deg);
  }
}