@import '../../../../styles/index';
.picker_input_wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.timer_picker{
  overflow: hidden;
  &_header{
    display: flex;
    width: 100%;
    padding: 10px;
    height: 40px;
    border: 1px solid $lightgray2;
    border-radius: 20px 20px 0px 0px;
    &_title{
      font-size: 18px;
      line-height: 22px;
      font-weight: $font-weight-bolder;
    }
    &_close{
      position: absolute;
      right: 15px;
      top:15px;
    }
  }
  &_body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: min(200px, 50vh);
    width: 100%;
    gap:20px;

    &_wrapper{
      height: min(150px, 40vh);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &_title{
        font-size: 14px;
        line-height: 17px;
        color: $darkBlue;
        font-weight: $font-weight-bolder;
        margin-bottom: 10px;
      }
      &_options{
        height: min(125px, 35vh);
        overflow-y: auto;
        scroll-behavior: smooth;
        &::-webkit-scrollbar{
          display: none;
        }
        .active{
          background: $gradientBlue;
          color: $white;
          border-radius: 10px;
          text-align: center;
        }
        .disabled{
          color: $lightgray2;
          cursor: not-allowed;

        }
        :last-child{
          margin-bottom: min(62.5px, 17.5vh);
        }
        &_option{
         
          font-size: 24px;
          padding: 5px;
        }
      }
    }
  }
  &_footer{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border: 1px solid $lightgray2;
    .confirm{
      &_button{
        padding: 13px 40px;
        border-radius: 10px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        background: $gradientBlue;
        color: white;
        margin-left: 5px;     
        width: 80%;
      }
      &_delete{}
    }
  }
}