.badget {
        flex: 1 0 auto;
        max-width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 10px 5px 5px;
        background-color: rgba(31, 31, 31, 0.04);
        border-radius: 8px;
        min-width: -webkit-fit-content;
        min-width: -moz-fit-content;
        min-width: fit-content;
        gap: 5px;



        &_text {
                font-weight: 500;
                font-size: 14px;

        }

        &_icon {
                background-color: white;
                border-radius: 50%;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
        }
}

.badget_small {
        &_icon {
                background-color: white;
                border-radius: 50%;
                padding: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                z-index: 304;
                max-height: 100%;


                &:hover {
                        background-color: black;

                        .info_card {
                                position: absolute;
                                display: block;
                                background-color: black;
                                opacity: 1;
                                color: white;
                                border-radius: 10px;
                                padding: 10px;
                                min-width: 100px;
                                max-width: 170px;
                                top: -33px;
                                right: 0;
                                z-index: 100;
                        }
                }

                .info_card {
                        display: none;
                }
        }
}